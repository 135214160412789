import * as d3 from "d3";
import { DrawData, SimpleTextMeta } from "../../../Interfaces";
import { interpolatePath } from "d3-interpolate-path";
import { interpolateString } from "d3";

const PRIMARY_VALUE = 3.6;
const SUFFIX_VALUE = 2;
const PRIMARY_HEIGHT = `${PRIMARY_VALUE}em`;
const SUFFIX_HEIGHT = `${SUFFIX_VALUE}em`;

export default function simpleText(
  drawData: DrawData,
  typeData: SimpleTextMeta
) {
  const { data, displayProperties } = drawData;
  const {
    suffix,
    format,
    modifier,
    trendline,
    goalValue,
    defaultValue
  } = typeData;
  const {
    id,
    parentWidth,
    parentHeight,
    contentSVG,
    defaultTransition,
    theme
  } = displayProperties;
  let textSVG, trendlineSVG, goalLineSVG;

  textSVG = contentSVG.select(`#${id} #text-${id}`);
  if (textSVG.empty()) {
    textSVG = contentSVG.append("g").attr("id", `text-${id}`);
  }
  trendlineSVG = contentSVG.select(`#${id} #trendline-${id}`);
  if (trendlineSVG.empty()) {
    trendlineSVG = contentSVG.append("g").attr("id", `trendline-${id}`);
  }
  goalLineSVG = contentSVG.select(`#${id} #goalLine-${id}`);
  if (goalLineSVG.empty()) {
    goalLineSVG = contentSVG
      .append("path")
      .attr("id", `goalLine-${id}`)
      .attr("fill", "none")
      .attr("class", "line")
      .style("stroke-width", "2")
      .attr("stroke", theme.widget.lineSecondary)
      .style("stroke-dasharray", "5,5");
  }

  let formatNumber: (d: string) => string = getFormatNumber();

  let text: string;
  if (data && data.length > 0) {
    if (trendline && trendline.summation) {
      let sum: number = 0;
      for (let i = 0; i < data.length; i++) {
        sum += +data[i].metrics[0].values[0];
      }
      text = sum.toString();
    } else {
      text = data[data.length - 1].metrics[0].values[0];
    }
    if (modifier) {
      // hardcoded data :(
      let desktopCount: number = 0;
      let desktopValue: number = 0;
      let mobileCount: number = 0;
      let mobileValue: number = 0;
      let tabletCount: number = 0;
      let tabletValue: number = 0;
      if (data[0]) {
        desktopCount = +data[0].metrics[0].values[0];
        desktopValue = +data[0].metrics[0].values[1];
      }
      if (data[1]) {
        mobileCount = +data[1].metrics[0].values[0];
        mobileValue = +data[1].metrics[0].values[1];
      }
      if (data[2]) {
        tabletCount = +data[2].metrics[0].values[0];
        tabletValue = +data[2].metrics[0].values[1];
      }

      let nxpTime = desktopValue + mobileValue + tabletValue;
      // let nxpTime = (desktopCount + mobileCount + tabletCount) * 45 * 1000;
      let remoteCount = mobileCount + tabletCount;
      let hanaTime =
        (desktopCount + remoteCount) * 2.5 * 60 * 1000 +
        remoteCount * 30 * 60 * 1000;
      text = Math.max(0, hanaTime - nxpTime).toString();
    }
  } else {
    text = defaultValue || "n/a";
  }
  // if (modifier) {
  //   if (modifier && data !== undefined) {
  //     //   let hanaTime: number = +text * 250000;
  //     //   num = +text * (data[data.length - 1].metrics[0].values[1] || 1);
  //     //   text = (Math.max(hanaTime - num, 0) / 60000).toPrecision(4).toString();
  //     let hanaTime: number = +text * (2.5 * 60 * 1000 + 0.5 * 60 * 60 * 1000);
  //     let saleHubTime: number = +text * (0.75 * 60 * 1000);
  //     num = hanaTime - saleHubTime; // in milliseconds
  //     num *= modifier;
  // }
  let textParts: string[] = formatText(text);
  if (suffix !== undefined) {
    textParts.push(suffix);
  }

  let availableHeight = parentHeight;
  if (trendline && trendline.active) {
    availableHeight /= 2;
  }

  let textHeight: number = getTextHeight();
  textSVG
    .selectAll("text")
    .data(textParts, (d: string, i: number) => i)
    .join(
      (enter: any) =>
        enter
          .append("text")
          .attr("x", (d: string, i: number) => getTextXpos(i))
          .attr("y", availableHeight * 0.5)
          .text((d: string) => d)
          .each(function(d: string, i: number, n: any) {
            n[i]._current = d;
          })
          .call((enter: any) => enter.transition(defaultTransition)),
      (update: any) =>
        update.call((update: any) =>
          update
            .transition(defaultTransition)
            .attr("x", (d: string, i: number) => getTextXpos(i))
            .attr("y", availableHeight * 0.5)
            .tween("text", function(d: string, i: number, n: any) {
              let inter = interpolateString(n[i]._current, d);
              n[i]._current = inter(1);
              return function(t: any) {
                d3.select(n[i]).text(formatNumber(inter(t)));
              };
            })
        ),
      (exit: any) => exit.call((exit: any) => exit.remove())
    )
    .style("fill", theme.text.colour)
    .style("font-size", (d: string, i: number) =>
      i % 2 ? SUFFIX_HEIGHT : PRIMARY_HEIGHT
    )
    .attr("transform", `translate(${0}, ${0.5 * textHeight})`);

  if (!trendline || !trendline.active) {
    trendlineSVG.style("opacity", 0);
  }
  if (data && trendline && trendline.active) {
    trendlineSVG.style("opacity", 1);
    let summation = 0;
    let trendData: [number, number][] = [];
    let minY: number = goalValue || Number.POSITIVE_INFINITY;
    let maxY: number = goalValue || Number.NEGATIVE_INFINITY;

    for (let i = 0; i < data.length; i++) {
      const { metrics } = data[i];
      let yVal: number = +metrics[0].values[0];
      if (trendline.summation) {
        yVal += summation;
        summation = yVal;
      }
      if (yVal > maxY) {
        maxY = yVal;
      }
      if (yVal < minY) {
        minY = yVal;
      }
      trendData.push([i, yVal]);
    }

    const maxX = trendData.length - 1;
    const xScale = d3
      .scaleLinear()
      .domain([0, maxX])
      .range([0, parentWidth * 0.8]);
    const yScale = d3
      .scaleLinear()
      .domain([minY, maxY])
      .range([parentHeight * 0.25, 0]);
    let lineGenerator = d3
      .line()
      .x(d => xScale(d[0]))
      .y(d => yScale(d[1]))
      .curve(d3.curveLinear);

    // let pathString = lineGenerator(trendData || [[0, 0]]);

    if (goalValue !== undefined && trendline.showGoal) {
      let goalString = lineGenerator([[0, goalValue], [maxX, goalValue]]);
      goalLineSVG
        .attr(
          "transform",
          `translate(${parentWidth * 0.1}, ${availableHeight +
            0.5 * textHeight})`
        )
        .transition(defaultTransition)
        .attr("d", goalString || "");
    }

    trendlineSVG
      .selectAll("path")
      .data([trendData])
      .join(
        (enter: any) =>
          enter
            .append("path")
            .style("opacity", 0)
            .attr("d", (d: [number, number][]) => lineGenerator(d))
            .call((enter: any) =>
              enter.transition(defaultTransition).style("opacity", 1)
            ),
        (update: any) =>
          update.call((update: any) =>
            update
              .transition(defaultTransition)
              .attrTween("d", function(
                d: [number, number][],
                i: number,
                n: any
              ) {
                let previous = d3.select(n[i]).attr("d");
                let current = lineGenerator(d) || "";
                return interpolatePath(previous, current);
              })
          ),
        (exit: any) =>
          exit.call((exit: any) =>
            exit
              .transition(defaultTransition)
              .style("opacity", 0)
              .remove()
          )
      )
      .attr("fill", "none")
      .attr("class", "line")
      .style("stroke-width", "2")
      .attr("stroke", theme.widget.linePrimary)
      .attr(
        "transform",
        `translate(${parentWidth * 0.1}, ${availableHeight + 0.5 * textHeight})`
      );
  }

  function formatText(text: string): string[] {
    let num: number;
    num = +text;

    switch (format) {
      case "value":
        return ["$" + num.toPrecision(3).toString()];
      case "time":
        return msToHMS(num);
      case "percentage":
        num = +text * 100;
        return [String(num.toPrecision(3))];
      case "blank":
        return [""];
      default:
        return [text];
    }
  }

  function getFormatNumber(): (d: string) => string {
    switch (format) {
      case "value":
        return (d: string) => parseFloat(d).toFixed(1);
      case "time":
        return (d: string) => d;
      case "percentage":
        return (d: string) => parseFloat(d).toFixed(1);
      case "blank":
        return (d: string) => d;
      default:
        return (d: string) => parseFloat(d).toFixed(0);
    }
  }

  function msToHMS(ms: number): string[] {
    const msPerMin = 60 * 1000;
    const msPerHour = msPerMin * 60;
    const msPerDay = msPerHour * 24;
    let days = Math.floor(ms / msPerDay);
    let hours = Math.floor((ms - days * msPerDay) / msPerHour);
    let minutes = Math.floor(
      (ms - days * msPerDay - hours * msPerHour) / msPerMin
    );
    // let seconds = Math.floor(
    //   (ms - days * msPerDay - hours * msPerHour - minutes * msPerMin) / 1000
    // );
    let daysArray = days === 0 ? [] : [days.toString(), "D"];
    let hoursArray = hours === 0 ? [] : [hours.toString(), "H"];
    let minutesArray = minutes === 0 ? [] : [minutes.toString(), "M"];
    return [...daysArray, ...hoursArray, ...minutesArray];
  }

  function getTextHeight() {
    let text = contentSVG
      .append("text")
      .text("height")
      .style("font-size", PRIMARY_HEIGHT);
    let height = text.node().getBoundingClientRect().height;
    text.remove();
    return height;
  }

  function getTextWidth(text: string[]) {
    let width: number = 0;
    let d3text = contentSVG.append("text");
    for (let i = 0; i < text.length; i++) {
      d3text
        .text(text[i])
        .style("font-size", i % 2 === 0 ? PRIMARY_HEIGHT : SUFFIX_HEIGHT);
      width += d3text.node().getComputedTextLength();
    }

    d3text.remove();
    return width;
  }

  function getTextXpos(i: number) {
    return (
      getTextWidth(textParts.slice(0, i)) +
      (parentWidth * 0.5 - getTextWidth(textParts) / 2)
    );
  }
}
