import * as d3 from "d3";
import { interpolateString } from "d3";
import { DrawData, StackedBarMeta } from "../../../Interfaces";
import {
  NutrienColours,
  ALT_NAME,
  TOTAL_CONTRACTS,
  EXP_CONTRACTS
} from "../d3Utilities";

const colours = d3
  .scaleOrdinal()
  .domain(["SAP", "Experience"])
  .range([
    NutrienColours.blue,
    NutrienColours.lightGreen,
    NutrienColours.blue,
    NutrienColours.lightGreen,
    NutrienColours.darkGreen,
    NutrienColours.steel,
    NutrienColours.red,
    NutrienColours.orange,
    NutrienColours.yellow
  ]);

const BAR_TOP = 0.35;
const BAR_HEIGHT = 0.35;
const BAR_OPACITY = 0.7;
const FLOAT_REGEX = /[\d.]+/;
const PERCENT_REGEX = /[\d.]+%/;

export default function stackedBar(
  drawData: DrawData,
  typeData: StackedBarMeta
) {
  const { data, displayProperties } = drawData;
  const {
    id,
    parentWidth,
    parentHeight,
    contentSVG,
    defaultTransition,
    theme
  } = displayProperties;
  const defaultTextHeight = getTextHeight();
  let barSVG, nameSVG, valueSVG;

  barSVG = contentSVG.select(`#${id} #bar-${id}`);
  if (barSVG.empty()) {
    barSVG = contentSVG.append("g").attr("id", `bar-${id}`);
  }
  nameSVG = contentSVG.select(`#${id} #name-${id}`);
  if (nameSVG.empty()) {
    nameSVG = contentSVG.append("g").attr("id", `name-${id}`);
  }
  valueSVG = contentSVG.select(`#${id} #value-${id}`);
  if (valueSVG.empty()) {
    valueSVG = contentSVG.append("g").attr("id", `value-${id}`);
  }

  interface BarSegment {
    value: number;
    start: number;
    label: string;
    percent: number;
  }

  if (data !== undefined) {
    let barData: { label: string; value: number }[] = [];
    for (let i = 0; i < data.length; i++) {
      let { dimensions, metrics } = data[i];
      let name = dimensions[0];
      let value = +metrics[0].values[0];
      if (ALT_NAME[name] !== undefined) {
        name = ALT_NAME[name];
      }
      barData.push({ label: name, value: value });
    }
    let totalEntry, expEntry;
    for (let i = 0; i < barData.length; i++) {
      if (barData[i].label === TOTAL_CONTRACTS) {
        totalEntry = barData[i];
      } else if (barData[i].label === EXP_CONTRACTS) {
        expEntry = barData[i];
      }
    }
    if (totalEntry && expEntry) {
      totalEntry.value -= expEntry.value;
    }

    let total = d3.sum(barData, d => d.value);
    let percent = d3
      .scaleLinear()
      .domain([0, total])
      .range([0, 100]);
    let cumulative = 0;
    let proportionalData = barData
      .map(d => {
        cumulative += d.value;
        let segment: BarSegment = {
          value: d.value,
          start: cumulative - d.value,
          label: d.label,
          percent: percent(d.value)
        };
        return segment;
      })
      .filter(d => d.value > 0);
    let xScale = d3
      .scaleLinear()
      .domain([0, total])
      .range([0, parentWidth * 0.8]);
    barSVG.attr(
      "transform",
      `translate(${parentWidth * 0.1}, ${parentHeight * BAR_TOP})`
    );
    barSVG
      .selectAll("rect")
      .data(proportionalData, (d: BarSegment) => d.label)
      .join(
        (enter: any) =>
          enter
            .append("rect")
            .attr("x", (d: BarSegment, i: number) =>
              i === 0 ? 0 : xScale(total)
            )
            .style("fill", (d: BarSegment, i: string) => colours(d.label))
            .call((enter: any) =>
              enter
                .transition(defaultTransition)
                .attr("x", (d: BarSegment) => xScale(d.start))
                .attr("width", (d: BarSegment) => xScale(d.value))
            ),
        (update: any) =>
          update.call((update: any) =>
            update
              .transition(defaultTransition)
              .attr("x", (d: BarSegment) => xScale(d.start))
              .attr("width", (d: BarSegment) => xScale(d.value))
          ),
        (exit: any) =>
          exit.call((exit: any) =>
            exit
              .transition(defaultTransition)
              .attr("x", (d: BarSegment, i: number) =>
                xScale(i === 0 ? d.start : total)
              )
              .attr("width", 0)
              .remove()
          )
      )
      .attr("height", parentHeight * BAR_HEIGHT)
      .style("opacity", BAR_OPACITY);

    nameSVG
      .selectAll(".text-label")
      .data(proportionalData, (d: BarSegment) => d.label)
      .join(
        (enter: any) =>
          enter
            .append("text")
            .attr("x", (d: BarSegment, i: number) =>
              i === 0 ? 0 : xScale(total)
            )
            .style("opacity", 0)
            .call((enter: any) =>
              enter
                .transition(defaultTransition)
                .text((d: BarSegment) => d.label)
                .attr("x", (d: BarSegment) =>
                  getDisplacement(d, xScale, d.label)
                )
                .style("opacity", 1)
            ),
        (update: any) =>
          update.call((update: any) =>
            update
              .transition(defaultTransition)
              .text((d: BarSegment) => d.label)
              .attr("x", (d: BarSegment) => getDisplacement(d, xScale, d.label))
          ),
        (exit: any) =>
          exit.call((exit: any) =>
            exit
              .transition(defaultTransition)
              .attr("x", (d: BarSegment, i: number) =>
                xScale(i === 0 ? d.start : total)
              )
              .attr("width", 0)
              .style("opacity", 0)
              .remove()
          )
      )
      .attr("class", "text-label")
      .style("fill", theme.text.colour)
      .attr(
        "y",
        () => parentHeight * (BAR_TOP + BAR_HEIGHT) + defaultTextHeight * 1.5
      );

    valueSVG
      .selectAll(".value-label")
      .data(proportionalData, (d: BarSegment) => d.label)
      .join(
        (enter: any) =>
          enter
            .append("text")
            .each(function(d: BarSegment, i: number, n: any) {
              n[i]._current = formatLabel(d);
            })
            .attr("x", (d: BarSegment, i: number) =>
              i === 0 ? 0 : xScale(total)
            )
            .style("opacity", 0)
            .call((enter: any) =>
              enter
                .transition(defaultTransition)
                .text((d: BarSegment) => formatLabel(d))
                .attr("x", (d: BarSegment) =>
                  getDisplacement(d, xScale, formatLabel(d))
                )
                .style("opacity", 1)
            ),
        (update: any) =>
          update.call((update: any) =>
            update
              .transition(defaultTransition)
              .text((d: BarSegment) => formatLabel(d))
              .attr("x", (d: BarSegment) =>
                getDisplacement(d, xScale, formatLabel(d))
              )
              .tween("text", function(d: BarSegment, i: number, n: any) {
                let inter = interpolateString(n[i]._current, formatLabel(d));
                n[i]._current = inter(1);
                return function(t: any) {
                  d3.select(n[i]).text(
                    inter(t)
                      .replace(FLOAT_REGEX, (match: string) =>
                        parseFloat(match).toFixed(0)
                      )
                      .replace(
                        PERCENT_REGEX,
                        (match: string) =>
                          `${parseFloat(match).toPrecision(3)}%`
                      )
                  );
                };
              })
          ),
        (exit: any) =>
          exit.call((exit: any) =>
            exit
              .transition(defaultTransition)
              .attr("x", (d: BarSegment, i: number) =>
                xScale(i === 0 ? d.start : total)
              )
              .attr("width", 0)
              .style("opacity", 0)
              .remove()
          )
      )
      .attr("class", "value-label")
      .style("fill", theme.text.colour)
      .attr("y", () => parentHeight * BAR_TOP - defaultTextHeight * 0.5);
  }

  function getDisplacement(
    d: BarSegment,
    scale: (input: number) => number,
    text: string
  ) {
    let width = getTextWidth(text);
    let displacement =
      scale(d.start) + 0.1 * parentWidth + scale(d.value) / 2 - width / 2;
    displacement = Math.min(parentWidth * 0.9 - width, displacement);
    displacement = Math.max(parentWidth * 0.1, displacement);
    return displacement;
  }

  function getTextHeight(): number {
    let text = contentSVG.append("text").text("height");
    let height = text.node().getBoundingClientRect().height;
    text.remove();
    return height;
  }

  function getTextWidth(text: string): number {
    let d3text = contentSVG.append("text").text(text);
    let width = d3text.node().getComputedTextLength();
    d3text.remove();
    return width;
  }

  // function addCommasToNumber(value: string): string {
  //   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  function formatLabel(segment: BarSegment): string {
    return `${segment.value.toFixed(0)} | ${segment.percent.toPrecision(3)}%`;
  }
}
