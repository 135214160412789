import * as d3 from "d3";
import { DrawData, MapNAMeta } from "../../../Interfaces";
import geoNorthAmerica from "../../../resources/ne_northamerica.json";
import geoNALakes from "../../../resources/ne_lakes.json";

const MAP_SCALE_FACTOR: number = 0.02;
const BASE_POINT_OPACITY: number = 0.6;
const MOUSEOVER_POINT_OPACITY: number = 0.9;
const POINT_RADIUS_EXP: number = 0.3;

type PointData = {
  key: string;
  label: string;
  value: number;
  radius: number;
  coords: [number, number];
};

export default function mapNorthAmerica(
  drawData: DrawData,
  mapNAMeta: MapNAMeta
) {
  const { data, displayProperties } = drawData;
  const {
    id,
    parentWidth,
    parentHeight,
    contentSVG,
    defaultTransition,
    theme
    // titleSize
  } = displayProperties;

  const { display } = mapNAMeta;

  let minSide = Math.min(parentWidth, parentHeight);
  let projection = d3
    .geoOrthographic()
    .scale(minSide)
    .translate([parentWidth * 0.5, parentHeight * 0.35])
    .rotate([92, -60, 10]);
  let geoGenerator = d3.geoPath().projection(projection);

  let landSVG, lakeSVG, geoPoints: any, tooltip: any;

  landSVG = contentSVG.select(`#${id} #land-${id}`);
  if (landSVG.empty()) {
    landSVG = contentSVG
      .append("g")
      .attr("fill", theme.widget.lineSecondary)
      .attr("stroke", theme.paper.background)
      .selectAll("path")
      .data(geoNorthAmerica.features)
      .enter()
      .append("path")
      .attr("id", `land-${id}`);
  }
  lakeSVG = contentSVG.select(`#${id} #lake-${id}`);
  if (lakeSVG.empty()) {
    lakeSVG = contentSVG
      .append("g")
      .attr("fill", theme.paper.background)
      .attr("stroke", theme.paper.background)
      .selectAll("path")
      .data(geoNALakes.features)
      .enter()
      .append("path")
      .attr("id", `lake-${id}`);
  }
  geoPoints = contentSVG.select(`#${id} #points-${id}`);
  if (geoPoints.empty()) {
    geoPoints = contentSVG
      .append("g")
      .style("fill", theme.widget.linePrimary)
      .attr("id", `points-${id}`);
  }
  tooltip = contentSVG.select(`#${id} #tooltip-${id}`);
  if (tooltip.empty()) {
    tooltip = contentSVG
      .append("text")
      .attr("class", "tooltip")
      .attr("id", `tooltip-${id}`)
      .style("fill", theme.text.colour)
      .attr("pointer-events", "none")
      .style("opacity", 0);
  }
  tooltip.text("");

  landSVG
    .transition(defaultTransition)
    .attr("d", (geoGenerator as unknown) as string); //lmao what

  lakeSVG
    .transition(defaultTransition)
    .attr("d", (geoGenerator as unknown) as string);

  if (data) {
    let pointData: PointData[] = [];
    for (let i = 0; i < data.length; i++) {
      let { dimensions, metrics } = data[i];
      let area = metrics[0].values[0];

      let radius =
        minSide *
        Math.max(
          MAP_SCALE_FACTOR / 2,
          Math.pow(area / Math.PI, POINT_RADIUS_EXP) * MAP_SCALE_FACTOR
        );
      let coords: [number, number] = projection([
        dimensions[0],
        dimensions[1]
      ]) || [0, 0]; //this is in lon, lat format
      let city = dimensions[2];
      pointData.push({
        key: city,
        label: `${city}: ${area}`,
        value: area,
        radius: radius,
        coords: coords
      });
    }

    switch (display) {
      case "points":
        drawPoints(pointData);
        break;
      case "heatmap":
        drawHeatmap(pointData);
        break;
      default:
        drawPoints(pointData);
    }
  }

  function drawHeatmap(pointData: PointData[]) {}

  function drawPoints(pointData: PointData[]) {
    pointData.sort((x: PointData, y: PointData) => {
      return d3.descending(x.value, y.value);
    });

    geoPoints
      .selectAll("circle")
      .data(pointData, (d: PointData) => d.key)
      .join(
        (enter: any) =>
          enter
            .append("circle")
            .style("opacity", (d: PointData) => calcOpacity(d))
            .call((enter: any) =>
              enter
                .transition(defaultTransition)
                .attr("r", (d: PointData) => d.radius)
            ),
        (update: any) =>
          update.call((update: any) =>
            update
              .transition(defaultTransition)
              .attr("r", (d: PointData) => d.radius)
              .style("opacity", (d: PointData) => calcOpacity(d))
          ),
        (exit: any) =>
          exit.call((exit: any) =>
            exit
              .transition(defaultTransition)
              .attr("r", 0)
              .remove()
          )
      )
      .on("mouseover", (d: PointData, i: number, n: any) => {
        //@ts-ignore
        d3.select(n[i]).style("opacity", MOUSEOVER_POINT_OPACITY);
        tooltip.style("opacity", MOUSEOVER_POINT_OPACITY);
        tooltip
          .text(d.label)
          .attr(
            "transform",
            `translate(${d.coords[0] + d.radius}, ${d.coords[1] - d.radius})`
          );
      })
      .on("mouseout", (d: PointData, i: number, n: any) => {
        tooltip.style("opacity", 0);
        d3.select(n[i]).style("opacity", calcOpacity(d));
      })

      .attr("cy", (d: PointData) => d.coords[1])
      .attr("cx", (d: PointData) => d.coords[0]);
  }
}

function calcOpacity(d: PointData) {
  // let modifier = Math.tanh(d.value / 200);
  let modifier =
    Math.tanh(d.value - Math.PI * Math.pow(d.radius, 2) * MAP_SCALE_FACTOR) *
    0.1;
  return Math.max(BASE_POINT_OPACITY + modifier, 0);
}
