import { Tile } from "../../../Interfaces";

export var layoutConfig: Tile[] = [
  { key: "northAmericaVisitors", row: 1, col: 11, width: 10, height: 11 },
  { key: "loginsOverTime", row: 12, col: 9, width: 8, height: 5 },
  { key: "numContracts", row: 1, col: 1, width: 4, height: 4 },
  { key: "timeSavedTicker", row: 1, col: 5, width: 3, height: 3 },
  { key: "salesUsers", row: 5, col: 1, width: 4, height: 6 },
  { key: "salesRegions", row: 11, col: 1, width: 4, height: 6 },
  { key: "contractActions", row: 12, col: 5, width: 4, height: 5 },
  { key: "contractCompare", row: 4, col: 5, width: 6, height: 4 },
  { key: "contractVolume", row: 8, col: 5, width: 6, height: 4 },
  { key: "deviceCategory", row: 12, col: 17, width: 4, height: 5 },
  { key: "activeUsers", row: 1, col: 8, width: 3, height: 3 }
];
