import * as d3 from "d3";
import { DrawData, LeaderboardMeta } from "../../../Interfaces";
import { interpolateString } from "d3";
import {
  limitText,
  getTextBox,
  ALT_NAME,
  ZVSM_APPROVE,
  DIGITAL_APPROVE
} from "../d3Utilities";

interface TextL10s {
  [key: string]: string;
}

interface LeaderboardItem {
  key: string;
  value: string;
}

const textLocalizations: TextL10s = {
  "/login": "Login",
  "/": "Home",
  "/contract/new/salesContract": "New Contract",
  "/contract/new/payment": "New Contract - Payment",
  "/contract/new/submit": "New Contract - Submit",
  "/contract/new/pricing": "New Contract - Pricing",
  "/contract/new/sources": "New Contract - Sources",
  "/contract/dashboard/recent": "Contract - Recently Created",
  "/reporting": "Reporting"
};

const FONT_MULTI = 1.2;
const FONT_SIZE = FONT_MULTI + "em";
const LINE_OPACITY = 0.5;
const LINE_OFFSET = 0.33;

export default function leaderboard(
  drawData: DrawData,
  leaderboardMeta: LeaderboardMeta
) {
  const { data, displayProperties } = drawData;
  const {
    id,
    parentWidth,
    parentHeight,
    contentSVG,
    defaultTransition,
    theme,
    titleSize
  } = displayProperties;

  let legendOffset: number = 2.5 * titleSize.height;
  let textHeight: number = getTextBox(contentSVG, "test", FONT_SIZE).height;
  let textSpacing: number = textHeight * 2;

  let nameSVG, valueSVG, lineSVG;
  valueSVG = contentSVG.select(`#${id} #values-${id}`);
  if (valueSVG.empty()) {
    valueSVG = contentSVG
      .append("g")
      .attr("id", `values-${id}`)
      .attr("fill", theme.text.colour)
      .attr("font-size", FONT_SIZE)
      .attr("transform", `translate(0, ${legendOffset})`);
  }
  nameSVG = contentSVG.select(`#${id} #names-${id}`);
  if (nameSVG.empty()) {
    nameSVG = contentSVG
      .append("g")
      .attr("id", `names-${id}`)
      .attr("fill", theme.text.colour)
      .attr("font-size", FONT_SIZE)
      .attr("transform", `translate(${textHeight}, ${legendOffset})`);
  }
  lineSVG = contentSVG.select(`#${id} #lines-${id}`);
  if (lineSVG.empty()) {
    lineSVG = contentSVG
      .append("g")
      .attr("id", `lines-${id}`)
      .style("stroke", theme.widget.lineSecondary)
      .attr("transform", `translate(0, ${legendOffset})`);
  }

  if (data !== undefined) {
    let items: LeaderboardItem[] = [];
    let dataAdjustment: number = 0;
    data.sort((a, b) => +b.metrics[0].values - +a.metrics[0].values);
    for (let i = 0; i < data.length; i++) {
      let { dimensions, metrics } = data[i];

      if (dimensions[0] === "(not set)") {
        continue;
      }
      if (
        legendOffset + (items.length + 0) * textSpacing >
        parentHeight * 0.9
      ) {
        break;
      }
      let text = dimensions[0];
      let value = metrics[0].values[0];

      // dumb hack
      if (text === ZVSM_APPROVE) {
        if (value > 200) {
          dataAdjustment = 41;
        }
      }
      if (text === DIGITAL_APPROVE) {
        value += dataAdjustment;
      }
      text = localizeText(text);
      items.push({ key: text, value: value });
    }
    items.sort((a: any, b: any) => b.value - a.value);
    nameSVG
      .selectAll("text")
      .data(items, (d: LeaderboardItem) => d.key)
      .join(
        (enter: any) =>
          enter
            .append("text")
            .style("opacity", 0)
            .attr("y", (d: LeaderboardItem, i: number) => i * textSpacing)
            .text((d: LeaderboardItem) =>
              limitText(
                d.key,
                parentWidth -
                  (getTextBox(contentSVG, d.value, FONT_SIZE).width +
                    2 * textHeight),
                FONT_SIZE,
                contentSVG
              )
            )
            .call((enter: any) =>
              enter.transition(defaultTransition).style("opacity", 1)
            ),
        (update: any) =>
          update.call((update: any) =>
            update
              .style("opacity", 1)
              .transition(defaultTransition)
              .attr("y", (d: LeaderboardItem, i: number) => i * textSpacing)
          ),
        (exit: any) =>
          exit.call((exit: any) =>
            exit
              .transition(defaultTransition)
              .style("opacity", 0)
              .remove()
          )
      );
    valueSVG
      .selectAll("text")
      .data(items, (d: LeaderboardItem) => d.key)
      .join(
        (enter: any) =>
          enter
            .append("text")
            .style("opacity", 0)
            .attr("y", (d: LeaderboardItem, i: number) => i * textSpacing)
            .attr(
              "x",
              (d: LeaderboardItem) =>
                parentWidth -
                (getTextBox(contentSVG, d.value, FONT_SIZE).width + textHeight)
            )
            .text((d: LeaderboardItem) => d.value)
            .each(function(d: LeaderboardItem, i: number, n: any) {
              n[i]._current = d;
            })
            .call((enter: any) =>
              enter.transition(defaultTransition).style("opacity", 1)
            ),
        (update: any) =>
          update.call((update: any) =>
            update
              .style("opacity", 1)
              .transition(defaultTransition)
              .attr("y", (d: LeaderboardItem, i: number) => i * textSpacing)
              .attr(
                "x",
                (d: LeaderboardItem) =>
                  parentWidth -
                  (getTextBox(contentSVG, d.value, FONT_SIZE).width +
                    textHeight)
              )
              .tween("text", function(d: LeaderboardItem, i: number, n: any) {
                let inter = interpolateString(n[i]._current, d.value);
                n[i]._current = inter(1);
                return function(t: any) {
                  d3.select(n[i]).text(parseFloat(inter(t)).toFixed(0));
                };
              })
          ),
        (exit: any) =>
          exit.call((exit: any) =>
            exit
              .transition(defaultTransition)
              .style("opacity", 0)
              .remove()
          )
      );

    lineSVG
      .selectAll("line")
      .data(items, (d: LeaderboardItem, i: number) => i)
      .join(
        (enter: any) =>
          enter
            .append("line")
            .style("opacity", 0)
            .attr("x1", 0)
            .attr("x2", parentWidth)
            .attr(
              "y1",
              (d: LeaderboardItem, i: number) => (i + LINE_OFFSET) * textSpacing
            )
            .attr(
              "y2",
              (d: LeaderboardItem, i: number) => (i + LINE_OFFSET) * textSpacing
            )
            .call((enter: any) =>
              enter
                .transition(defaultTransition)
                .style("opacity", (d: LeaderboardItem, i: number) =>
                  i === items.length - 1 ? 0 : LINE_OPACITY
                )
            ),
        (update: any) =>
          update.call((update: any) =>
            update
              .transition(defaultTransition)
              .style("opacity", (d: LeaderboardItem, i: number) =>
                i === items.length - 1 ? 0 : LINE_OPACITY
              )
          ),
        (exit: any) =>
          exit.call((exit: any) =>
            exit
              .transition(defaultTransition)
              .style("opacity", 0)
              .remove()
          )
      );
  }
}

function localizeText(text: string) {
  if (textLocalizations[text] !== undefined) {
    return textLocalizations[text];
  }
  if (ALT_NAME[text] !== undefined) {
    return ALT_NAME[text];
  }
  return text;
}
