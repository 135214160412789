import React from "react";

interface Props {}
interface State {}

class Help extends React.Component<Props, State> {
  //   constructor(props: Props) {
  //     super(props);
  //   }

  render() {
    return <React.Fragment>Help</React.Fragment>;
  }
}

export default Help;
