import { DateRange, HanaQueryParams } from "../../Interfaces";
import GoogleDrive from "../GoogleDrive";

// run locally with "C:\path\to\chrome.exe" --disable-web-security --user-data-dir=~/chromeTemp

const ROOT = "https://exppwmis.nutrien.com:5443/restv2/reporting/";
// const LIST_ENDPOINT =
//   "salesContract/list?page_from=1&page_to=2&reporting_period=ALL";

const CACHE_NAME = "nxp-cache";
const CACHE_DURATION = 10 * 60 * 1000;
const ISODATEREGEX = /[\d]{4}-[0-1][\d]-[0-3][\d]/;
const DIGITREGEX = /^[1-9]\d*/;

class nxpAPI {
  private drive: GoogleDrive;
  private AUTH_KEY: string = "";
  constructor() {
    this.query = this.query.bind(this);
    this.getFromCache = this.getFromCache.bind(this);
    this.initCache = this.initCache.bind(this);
    this.getKey = this.getKey.bind(this);
    this.drive = new GoogleDrive();
    this.initCache();
  }

  async getKey() {
    let key: string = await this.drive.getWebMethodsKey();
    this.AUTH_KEY = key;
  }

  initCache() {
    caches.delete(CACHE_NAME);
    setInterval(() => {
      console.log("purging cache", CACHE_NAME);
      caches.delete(CACHE_NAME);
    }, CACHE_DURATION);
  }

  async getFromCache(query: string): Promise<Response | undefined> {
    let request: Request = new Request(query);
    return caches.open(CACHE_NAME).then((cache: Cache) => cache.match(request));
  }

  async saveInCache(query: string, response: Response) {
    let request: Request = new Request(query);
    caches
      .open(CACHE_NAME)
      .then((cache: Cache) => cache.put(request, response));
  }

  async get(endpoint: string): Promise<Response> {
    if (this.AUTH_KEY === "") {
      await this.getKey();
    }
    let cacheResult: Response | undefined = await this.getFromCache(endpoint);

    return new Promise(async (resolve, reject) => {
      if (cacheResult === undefined) {
        fetch(ROOT + endpoint, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa("api.sales:" + this.AUTH_KEY)
          },
          mode: "cors"
        }).then((response: Response) => {
          if (response.ok) {
            this.saveInCache(endpoint, response.clone());
            resolve(response);
          } else {
            reject("Error reaching " + ROOT + endpoint);
          }
        });
      } else {
        resolve(cacheResult);
        return;
      }
    });
  }

  query(params: HanaQueryParams) {
    const { dateRanges, endpointType } = params;
    let query = this.formQuery(endpointType, dateRanges[0]);
    return this.get(query);
  }

  formQuery(endpoint: string, daterange: DateRange) {
    return `${endpoint}?date_from=${this.gaToISO8601(
      daterange.startDate
    )}&date_to=${this.gaToISO8601(daterange.endDate)}`;
  }

  gaToISO8601(gaDate: string) {
    if (gaDate.match(ISODATEREGEX)) {
      return gaDate;
    } else if (gaDate === "today") {
      return new Date().toISOString().match(ISODATEREGEX);
    } else {
      let numDays: number = +(gaDate.match(DIGITREGEX) || 0);
      let date = new Date();
      date.setDate(date.getDate() - numDays);
      return date.toISOString().match(ISODATEREGEX);
    }
  }

  // convertData(response: any, type: HanaEndpoint) {
  //   switch (type) {
  //     case "contractsByDateRange":
  //       return response.results.length;
  //     case "createdContractTotals":
  //       const results = response.results;
  //       const digital: number = +results.total_contracts_digital;
  //       const total: number = +results.total_contracts;
  //       return digital / total;
  //     default:
  //       return;
  //   }
  // }
}

export default nxpAPI;
