import { UploadParams } from "../../Interfaces";

/* globals gapi */

// const VIEW_ID: string = "196744401";
const PATH: string = "/upload/drive/v3/files";
// const CORS_PROXY: string = "https://cors-anywhere.herokuapp.com/";
//   const PATH: string =
//   "https://www.googleapis.com/upload/drive/v3/files?uploadType=media";

const JSONMIME = "application/json";
const FILEMIME = "application/vnd.google-apps.document";
const FOLDERMIME = "application/vnd.google-apps.folder";

const PROFILES_ID = "1QPMm54AhPGH_SqrodzsK8VRp_7WvY3sE";
const RESOURCES_ID = "1rZWNcOv6D9JRgYAyRFuaeP-D6PjC1ffF";
const THEMES_ID = "1ObaxoEtYwRuB2UxjSK_ryXItBAwmcioH";
const WIDGETS_ID = "1hvc23DynavbwBpoAKjS_N3e4Usz4h4Kc";
const WEBMETHODS_ID = "1qdbZaOqhRHN__ghKQGyOoQp-g1RiXrk2";

const BOUNDARY: string = "--boundarystringPIZ9ADGFO0VL";
const DELIMITER: string = "\r\n--" + BOUNDARY + "\r\n";
const CLOSE_DELIM: string = "\r\n--" + BOUNDARY + "--";

export type FolderId =
  | typeof PROFILES_ID
  | typeof RESOURCES_ID
  | typeof THEMES_ID
  | typeof WIDGETS_ID;

export type MimeType = typeof JSONMIME | typeof FILEMIME | typeof FOLDERMIME;

class GoogleDrive {
  async getWebMethodsKey(): Promise<string> {
    return new Promise(async resolve => {
      await gapi.client.load("drive", "v3", async () => {
        let files: any = await this.getFolderContents(WEBMETHODS_ID);
        resolve(files[0].name);
      });
    });
  }

  upload(uploadParams: UploadParams) {
    const { name, uploadType, parentId, content } = uploadParams;
    let metaData = {
      name: name,
      mimeType: uploadType,
      parents: [parentId]
    };
    let multipartRequestBody =
      DELIMITER +
      "Content-Type: application/json\r\n\r\n" +
      JSON.stringify(metaData) +
      DELIMITER +
      `Content-Type: ${uploadType}\r\n\r\n` +
      content +
      CLOSE_DELIM;
    let request = gapi.client.request({
      path: PATH,
      method: "POST",
      params: { uploadType: "multipart" },
      headers: {
        "Content-Type": `multipart/related; boundary=${BOUNDARY}`
      },
      body: multipartRequestBody
    });
    request.execute(r => console.log(r));
  }

  // update(id: string, content: string) {
  //   let url = `https://www.googleapis.com${PATH}/${id}?uploadType=media`;

  //   var xhr = new XMLHttpRequest();
  //   xhr.responseType = "json";
  //   xhr.onreadystatechange = function() {
  //     if (xhr.readyState !== XMLHttpRequest.DONE) {
  //       return;
  //     }
  //     callback(xhr.response);
  //   };
  //   xhr.open("PATCH", url);
  //   xhr.setRequestHeader(
  //     "Authorization",
  //     "Bearer " + gapi.auth.getToken().access_token
  //   );
  //   xhr.send(new Blob([JSON.stringify(content)], { type: JSONMIME }));
  //   function callback(r: any) {
  //     console.log(r);
  //   }
  // }

  async getFolderContents(folderId: string, params?: string) {
    if (params === undefined) {
      params = "";
    }
    let contents: any[] = [];
    //@ts-ignore
    await gapi.client.drive.files
      .list({
        q: `'${folderId}' in parents ${params}`,
        fields: "files(id, name, mimeType, parents)"
      })
      .then(function(response: any) {
        contents = response.result.files;
      });
    return contents;
  }

  // async getFileContents(id: string, callback: (response: any) => void) {
  //   //@ts-ignore
  //   gapi.client.drive.files
  //     .get({
  //       fileId: id,
  //       fields: "webContentLink"
  //     })
  //     .then(
  //       function(success: any) {
  //         let webContentLink = success.result.webContentLink;
  //         let xhr = new XMLHttpRequest();
  //         xhr.onreadystatechange = function() {
  //           if (xhr.readyState !== XMLHttpRequest.DONE) {
  //             return;
  //           }
  //           callback(xhr.response);
  //         };
  //         // xhr.open("GET", CORS_PROXY + webContentLink, true);
  //         xhr.open("GET", webContentLink, true);
  //         xhr.setRequestHeader(
  //           "Content-Type",
  //           "application/x-www-form-urlencoded"
  //         );
  //         xhr.send();
  //       },
  //       function(err: any) {
  //         console.log(err);
  //         console.log("Error " + err.result.error.message);
  //       }
  //     );
  // }

  // async saveWidget(widget: Widget) {
  //   if (await this.widgetExists(widget.title)) {
  //     console.log("A widget with this title already exists");
  //   } else {
  //     let uploadParams: UploadParams = {
  //       name: widget.title,
  //       uploadType: JSONMIME,
  //       parentId: WIDGETS_ID,
  //       content: JSON.stringify(widget)
  //     };
  //     this.upload(uploadParams);
  //   }
  // }

  // async widgetExists(name: string) {
  //   let matchedWidgets = await this.getFolderContents(
  //     WIDGETS_ID,
  //     `and name = '${name}'`
  //   );
  //   return matchedWidgets.length > 0;
  // }

  // async getWidgets(names: string[]) {
  //   let widgets: Widget[] = [];
  //   let allWidgets = await this.getFolderContents(WIDGETS_ID);
  //   let waiting = 0;
  //   for (let i = 0; i < allWidgets.length; i++) {
  //     if (names.includes(allWidgets[i].name)) {
  //       this.getFileContents(allWidgets[i].id, getWidgetCallback);
  //       waiting++;
  //     }
  //   }
  //   waitForCallback();
  //   console.log(widgets);
  //   return widgets;

  //   function getWidgetCallback(contents: any) {
  //     widgets.push(JSON.parse(contents));
  //     waiting--;
  //   }

  //   function waitForCallback() {
  //     if (waiting > 0) {
  //       setTimeout(waitForCallback, 1000);
  //       return;
  //     }
  //     return;
  //   }
  // }
}

export default GoogleDrive;
