export const DIGITAL_APPROVE: string = "total_approved_digital";
export const DIGITAL_REJECT: string = "total_rejected_digital";
export const ZVSM_APPROVE: string = "total_approved_zvsm";
export const ZVSM_REJECT: string = "total_rejected_zvsm";

export const TOTAL_CONTRACTS: string = "total_contracts";
export const EXP_CONTRACTS: string = "total_contracts_digital";
export const SAP_VOLUME: string = "total_volume_mt_non_digital";
export const EXP_VOLUME: string = "total_volume_mt_digital";

export let ALT_NAME: { [dimension: string]: string } = {};

ALT_NAME[DIGITAL_APPROVE] = "Experience Approval";
ALT_NAME[DIGITAL_REJECT] = "Experience Rejection";
ALT_NAME[ZVSM_APPROVE] = "SAP Approval";
ALT_NAME[ZVSM_REJECT] = "SAP Rejection";

ALT_NAME[TOTAL_CONTRACTS] = "SAP";
ALT_NAME[EXP_CONTRACTS] = "Experience";
ALT_NAME[SAP_VOLUME] = "SAP";
ALT_NAME[EXP_VOLUME] = "Experience";

export function limitText(
  text: string,
  width: number,
  FONT_SIZE: string,
  contentSVG: any
): string {
  let textSVG = contentSVG
    .append("text")
    .attr("font-size", FONT_SIZE)
    .text(text);
  let textNode = textSVG.node();
  let charSubtract: number = 2;
  let subString: string = "";
  while (textNode.getComputedTextLength() > width) {
    if (charSubtract > text.length - 5) {
      break;
    }
    subString =
      text.substring(0, Math.max(text.length - charSubtract, 0)) + "...";
    textSVG.text(subString);
    charSubtract += 2;
  }
  textSVG.remove();
  if (subString === "") {
    return text;
  }
  return subString;
}

export function getTextBox(contentSVG: any, text: string, fontSize: string) {
  let textSVG = contentSVG
    .append("text")
    .attr("font-size", fontSize)
    .text(text);
  let clientRect = textSVG.node().getBoundingClientRect();
  textSVG.remove();
  return clientRect;
}

export enum NutrienColours {
  lightGreen = "#97D700",
  darkGreen = "#509E2F",
  orange = "#C4622D",
  red = "#7F3035",
  blue = "#256BA2",
  steel = "#AFCDD7",
  yellow = "#DECD63"
}
