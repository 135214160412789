import { WidgetMap } from "../../../Interfaces";

export var widgets: WidgetMap = {
  northAmericaVisitors: [
    {
      title: "Today's Activity",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [
            { name: "ga:longitude" },
            { name: "ga:latitude" },
            { name: "ga:city" }
          ],
          orderBys: [],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "mapNorthAmerica",
          display: "points"
        },
        tooltip:
          "Shows the number of sessions (visits at least 30 mins apart) today from any city"
      }
    },
    {
      title: "7 Day Activity",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [
            { name: "ga:longitude" },
            { name: "ga:latitude" },
            { name: "ga:city" }
          ],
          orderBys: [],
          dateRanges: [
            {
              startDate: "7daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "mapNorthAmerica",
          display: "points"
        },
        tooltip:
          "Shows the number of sessions (visits at least 30 mins apart) over the last 7 days from any city"
      }
    },
    {
      title: "Monthly Activity",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [
            { name: "ga:longitude" },
            { name: "ga:latitude" },
            { name: "ga:city" }
          ],
          orderBys: [],
          dateRanges: [
            {
              startDate: "30daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "mapNorthAmerica",
          display: "points"
        },
        tooltip:
          "Shows the number of sessions (visits at least 30 mins apart) over the last 30 days from any city"
      }
    },
    {
      title: "All Time Activity",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [
            { name: "ga:longitude" },
            { name: "ga:latitude" },
            { name: "ga:city" }
          ],
          orderBys: [],
          dateRanges: [
            {
              startDate: "2019-07-01",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "mapNorthAmerica",
          display: "points"
        },
        tooltip:
          "Shows the number of sessions (visits at least 30 mins apart) from all cities"
      }
    }
  ],
  weeklyNaVisitors: [
    {
      title: "Weekly Map",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [
            { name: "ga:longitude" },
            { name: "ga:latitude" },
            { name: "ga:city" }
          ],
          orderBys: [],
          dateRanges: [
            {
              startDate: "7daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "mapNorthAmerica",
          display: "points"
        }
      }
    }
  ],
  loginsOverTime: [
    {
      title: "Hourly Visits",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:date" }, { name: "ga:hour" }],
          orderBys: [{ fieldName: "ga:hour", sortOrder: "ASCENDING" }],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "timePlot",
          timeUnit: "hours",
          timePeriod: "days",
          display: "bar"
        },
        tooltip:
          "Visits are counted as any usage of the site at least 30 mins after any previous activity"
      }
    },
    {
      title: "Daily Visits",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:date" }],
          orderBys: [{ fieldName: "ga:date", sortOrder: "ASCENDING" }],
          dateRanges: [
            {
              startDate: "6daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "timePlot",
          timeUnit: "days",
          timePeriod: "weeks",
          display: "line"
        },
        tooltip:
          "Visits are counted as any usage of the site at least 30 mins after any previous activity"
      }
    },
    {
      title: "Weekly Visits",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:date" }],
          orderBys: [{ fieldName: "ga:date", sortOrder: "ASCENDING" }],
          dateRanges: [
            {
              startDate: "29daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "timePlot",
          timeUnit: "weeks",
          timePeriod: "months",
          display: "line"
        },
        tooltip:
          "Visits are counted as any usage of the site at least 30 mins after any previous activity"
      }
    },
    {
      title: "Monthly Visits",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:date" }],
          orderBys: [{ fieldName: "ga:date", sortOrder: "ASCENDING" }],
          dateRanges: [
            {
              startDate: "13monthsAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "timePlot",
          timeUnit: "months",
          timePeriod: "years",
          display: "line"
        },
        tooltip:
          "Visits are counted as any usage of the site at least 30 mins after any previous activity"
      }
    }
  ],

  // timeSavedTicker: [
  //   {
  //     title: "Total Time Saved",
  //     query: {
  //       refreshInterval: 1200000,
  //       parameters: {
  //         metrics: [
  //           { expression: "ga:totalEvents" },
  //           { expression: "ga:avgEventValue" }
  //         ],
  //         dimensions: [{ name: "ga:deviceCategory" }],
  //         filters: "ga:eventAction==Contract Created",
  //         orderBys: [],
  //         dateRanges: [
  //           {
  //             startDate: "2019-07-01",
  //             endDate: "today"
  //           }
  //         ]
  //       }
  //     },
  //     widgetData: {
  //       widgetSize: {
  //         width: 1,
  //         height: 1
  //       },
  //       typeData: {
  //         type: "simpleText",
  //         modifier: 1,
  //         format: "time"
  //       },
  //       tooltip:
  //         "Calculated based on the number of contracts, and the difference between contract creation time in NXP and estimated contract creation time in SAP. Full formula per contract: 2.5 mins average SAP entry time - average NXP entry time + 30 mins saved travel if on mobile"
  //     }
  //   }
  // ],

  activeUsers: [
    {
      title: "Visitors Today",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:Users" }],
          dimensions: [{ name: "ga:nthDay" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          defaultValue: "0",
          trendline: {
            active: false,
            summation: false
          }
        },
        tooltip:
          "Visitor uniqueness is tracked by browser cookies, so clearing your local storage or visiting from a new device will appear as a new visitor"
      }
    },
    {
      title: "Visitors - 7 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:7dayUsers" }],
          dimensions: [{ name: "ga:nthDay" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "7daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          defaultValue: "0",
          trendline: {
            active: true,
            summation: false
          }
        },
        tooltip:
          "Visitor uniqueness is tracked by browser cookies, so clearing your local storage or visiting from a new device will appear as a new visitor"
      }
    },
    {
      title: "Visitors - 30 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:30DayUsers" }],
          dimensions: [{ name: "ga:nthDay" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "30daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          defaultValue: "0",
          trendline: {
            active: true,
            summation: false
          }
        },
        tooltip:
          "Visitor uniqueness is tracked by browser cookies, so clearing your local storage or visiting from a new device will appear as a new visitor"
      }
    },
    {
      title: "Total Unique Visitors",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:users" }],
          dimensions: [],
          orderBys: [],
          dateRanges: [
            {
              startDate: "2019-07-01",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          defaultValue: "0",
          trendline: {
            active: false,
            summation: false
          }
        },
        tooltip:
          "Visitor uniqueness is tracked by browser cookies, so clearing your local storage or visiting from a new device will appear as a new visitor"
      }
    }
  ],

  deviceCategory: [
    {
      title: "Devices Today",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:deviceCategory" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "pieChart"
        },
        tooltip:
          "Ratio of device category from any visit at least 30 mins after a previous visit"
      }
    },
    {
      title: "Devices - 7 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:deviceCategory" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "7daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "pieChart"
        },
        tooltip:
          "Ratio of device category from any visit at least 30 mins after a previous visit"
      }
    },

    {
      title: "Devices - 30 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:deviceCategory" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "30daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "pieChart"
        },
        tooltip:
          "Ratio of device category from any visit at least 30 mins after a previous visit"
      }
    },
    {
      title: "Devices - All Time",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:deviceCategory" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "2019-07-01",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "pieChart"
        },
        tooltip:
          "Ratio of device category from any visit at least 30 mins after a previous visit"
      }
    }
  ],

  leaderboardDevices: [
    {
      title: "Mobile Devices - Today",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:users" }],
          dimensions: [{ name: "ga:mobileDeviceModel" }],
          orderBys: [{ fieldName: "ga:users", sortOrder: "DESCENDING" }],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Mobile visits by device model"
      }
    },
    {
      title: "Mobile Devices - 7 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:users" }],
          dimensions: [{ name: "ga:mobileDeviceModel" }],
          orderBys: [{ fieldName: "ga:users", sortOrder: "DESCENDING" }],
          dateRanges: [
            {
              startDate: "6daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Mobile visits by device model"
      }
    },
    {
      title: "Mobile Devices - 30 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:users" }],
          dimensions: [{ name: "ga:mobileDeviceModel" }],
          orderBys: [{ fieldName: "ga:users", sortOrder: "DESCENDING" }],
          dateRanges: [
            {
              startDate: "29daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Mobile visits by device model"
      }
    },
    {
      title: "Mobile Devices - All Time",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:users" }],
          dimensions: [{ name: "ga:mobileDeviceModel" }],
          orderBys: [{ fieldName: "ga:users", sortOrder: "DESCENDING" }],
          dateRanges: [
            {
              startDate: "2019-07-01",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Mobile visits by device model"
      }
    }
  ]
};

// export default widgets,
