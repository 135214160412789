import * as d3 from "d3";
import { interpolateString } from "d3";
import { DrawData, LabelValue, PieChartMeta } from "../../../Interfaces";
import {
  getTextBox,
  NutrienColours,
  ALT_NAME,
  DIGITAL_APPROVE,
  DIGITAL_REJECT,
  ZVSM_APPROVE,
  ZVSM_REJECT
} from "../d3Utilities";

const DEFAULT_OPACITY = 0.8;
const MOUSEOVER_POINT_OPACITY = 1;
const FLOAT_REGEX = /[\d.]+/;

const FONT_SIZE = "1em";
const CENTER_HORI = 0.5;
const CENTER_VERT = 0.52;

const colours = d3
  .scaleOrdinal()
  .domain([
    "Desktop",
    "Mobile",
    "Tablet",
    ALT_NAME[DIGITAL_APPROVE],
    ALT_NAME[DIGITAL_REJECT],
    ALT_NAME[ZVSM_APPROVE],
    ALT_NAME[ZVSM_REJECT]
  ])
  .range([
    NutrienColours.blue,
    NutrienColours.lightGreen,
    NutrienColours.darkGreen,
    NutrienColours.lightGreen,
    NutrienColours.darkGreen,
    NutrienColours.blue,
    NutrienColours.steel,
    NutrienColours.orange,
    NutrienColours.red
  ]);

interface centroid {
  idx: number;
  x: number;
  y: number;
}

export default function pieChart(drawData: DrawData, typeData: PieChartMeta) {
  const { data, displayProperties } = drawData;
  const {
    id,
    parentWidth,
    parentHeight,
    contentSVG,
    defaultTransition,
    theme
  } = displayProperties;

  const radius = Math.min(parentWidth, parentHeight) / 2;
  const innerRadius = radius * 0.2;
  const outerRadius = radius * 0.65;
  const arcGen = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius);

  let pieSVG, legendSVG: any, lineSVG, tooltipSVG: any;

  pieSVG = contentSVG.select(`#${id} #pie-slices-${id}`);
  if (pieSVG.empty()) {
    pieSVG = contentSVG
      .append("g")
      .attr("id", `pie-slices-${id}`)
      .attr("class", "slices")
      .attr(
        "transform",
        `translate(${parentWidth * CENTER_HORI}, ${parentHeight * CENTER_VERT})`
      );
  }

  legendSVG = contentSVG.select(`#${id} #pie-legend-${id}`);
  if (legendSVG.empty()) {
    legendSVG = contentSVG
      .append("g")
      .attr("id", `pie-legend-${id}`)
      .attr(
        "transform",
        `translate(${parentWidth * CENTER_HORI}, ${parentHeight * CENTER_VERT})`
      );
  }

  lineSVG = contentSVG.select(`#${id} #pie-lines-${id}`);
  if (lineSVG.empty()) {
    lineSVG = contentSVG
      .append("g")
      .attr("id", `pie-lines-${id}`)
      .attr(
        "transform",
        `translate(${parentWidth * CENTER_HORI}, ${parentHeight * CENTER_VERT})`
      );
  }

  tooltipSVG = contentSVG.select(`#${id} #tooltip-${id}`);
  if (tooltipSVG.empty()) {
    tooltipSVG = contentSVG
      .append("text")
      .attr("class", "tooltip")
      .attr("id", `tooltip-${id}`)
      .style("fill", theme.text.colour)
      .attr("pointer-events", "none")
      .style("opacity", 0)
      .attr(
        "transform",
        `translate(${parentWidth * CENTER_HORI}, ${parentHeight * CENTER_VERT +
          outerRadius * 1.1})`
      );
  }

  if (data) {
    let pieData: LabelValue[] = [];
    let label: string;
    let value: number;
    let dataAdjustment: number = 0;
    for (let i = 0; i < data.length; i++) {
      const { dimensions, metrics } = data[i];
      label = dimensions[0];
      value = +metrics[0].values[0];

      //begin dumb hacks
      if (label === ZVSM_APPROVE) {
        if (value > 200) {
          dataAdjustment = 41;
        }
      }
      if (label === DIGITAL_APPROVE) {
        value += dataAdjustment;
      }
      if (ALT_NAME[label] !== undefined) {
        label = ALT_NAME[label];
      }
      label = label.replace(/^\w/, c => c.toLocaleUpperCase());
      //end dumb hacks

      pieData.push({ label: label, value: value });
    }

    const pieGenerator = d3
      .pie<LabelValue>()
      .value((d: LabelValue): number => d.value)
      .sort(null);

    let centroids: centroid[] = [];
    let pieArcDatum: d3.PieArcDatum<LabelValue>[] = pieGenerator(pieData);
    for (let i = 0; i < pieArcDatum.length; i++) {
      let arcCentroid = arcGen.centroid(genDefaultArc(pieArcDatum[i]));
      centroids.push({ x: arcCentroid[0], y: arcCentroid[1], idx: i });
    }

    const yLevels: number[] = getNewYLevels(
      JSON.parse(JSON.stringify(centroids))
    );

    pieSVG
      .selectAll("path")
      .data(pieArcDatum, (d: d3.PieArcDatum<LabelValue>) => d.data.label)
      .join(
        (enter: any) =>
          enter
            .append("path")
            .each(function(d: d3.PieArcDatum<LabelValue>, i: number, n: any) {
              n[i]._current = d;
            })
            .attr("d", arcGen)
            .style("opacity", 0)
            .attr("fill", (d: d3.PieArcDatum<LabelValue>, i: any) =>
              colours(d.data.label)
            )
            .call((enter: any) =>
              enter
                .transition(defaultTransition)
                .style("opacity", DEFAULT_OPACITY)
                .attrTween("d", arcTween)
            ),
        (update: any) =>
          update.call((update: any) =>
            update
              .transition(defaultTransition)
              .attr("fill", (d: any, i: any) => colours(d.data.label))
              .attrTween("d", arcTween)
          ),
        (exit: any) =>
          exit.call((exit: any) =>
            exit
              .transition(defaultTransition)
              .style("opacity", 0)
              .remove()
          )
      )
      .on("mouseover", (d: d3.PieArcDatum<LabelValue>, i: number, n: any) => {
        d3.select(n[i]).style("opacity", MOUSEOVER_POINT_OPACITY);
        tooltipSVG.style("opacity", MOUSEOVER_POINT_OPACITY);
        tooltipSVG
          .text(`${d.data.label}: ${d.data.value}`)
          .attr("x", getTooltipOffset()[0])
          .attr("y", getTooltipOffset()[1]);
      })
      .on("mouseout", (d: d3.PieArcDatum<LabelValue>, i: number, n: any) => {
        tooltipSVG.style("opacity", 0);
        d3.select(n[i]).style("opacity", DEFAULT_OPACITY);
      });

    legendSVG
      .selectAll("text")
      .data(pieArcDatum, (d: d3.PieArcDatum<LabelValue>) => d.data.label)
      .join(
        (enter: any) =>
          enter
            .append("text")
            .style("opacity", 0)
            .each(function(d: d3.PieArcDatum<LabelValue>, i: number, n: any) {
              n[i]._current = formatSlice(d);
            })
            .text((d: d3.PieArcDatum<LabelValue>) => formatSlice(d))
            .attr("y", (d: d3.PieArcDatum<LabelValue>, i: number, n: any) =>
              getTextYPos(i, n, yLevels)
            )
            .attr("x", (d: d3.PieArcDatum<LabelValue>, i: number, n: any) =>
              getTextXPos(d, i, n)
            )
            .call((enter: any) =>
              enter.transition(defaultTransition).style("opacity", 1)
            ),
        (update: any) =>
          update.call((update: any) =>
            update
              .transition(defaultTransition)
              .text((d: d3.PieArcDatum<LabelValue>) => formatSlice(d))
              .attr("y", (d: d3.PieArcDatum<LabelValue>, i: number, n: any) =>
                getTextYPos(i, n, yLevels)
              )
              .attr("x", (d: d3.PieArcDatum<LabelValue>, i: number, n: any) =>
                getTextXPos(d, i, n)
              )
              .tween("text", function(
                d: d3.PieArcDatum<LabelValue>,
                i: number,
                n: any
              ) {
                let inter = interpolateString(n[i]._current, formatSlice(d));
                n[i]._current = inter(1);
                return function(t: any) {
                  d3.select(n[i]).text(
                    inter(t).replace(FLOAT_REGEX, (match: string) =>
                      parseFloat(match).toPrecision(3)
                    )
                  );
                };
              })
          ),
        (exit: any) =>
          exit.call((exit: any) =>
            exit
              .transition(defaultTransition)
              .style("opacity", 0)
              .remove()
          )
      )
      .attr("fill", theme.text.colour);

    let lineGenerator: d3.Line<[number, number]> = d3.line();

    lineSVG
      .selectAll("path")
      .data(pieArcDatum, (d: d3.PieArcDatum<LabelValue>) => d.data.label)
      .join(
        (enter: any) =>
          enter
            .append("path")
            .style("opacity", 0)
            .attr("d", (d: d3.PieArcDatum<LabelValue>, i: number, n: any) =>
              lineGenerator(getLinePoints(i, centroids, yLevels))
            )
            .call((enter: any) =>
              enter.transition(defaultTransition).style("opacity", 1)
            ),
        (update: any) =>
          update.call((update: any) =>
            update
              .transition(defaultTransition)
              .attr("d", (d: d3.PieArcDatum<LabelValue>, i: number, n: any) =>
                lineGenerator(getLinePoints(i, centroids, yLevels))
              )
          ),
        (exit: any) =>
          exit.call((exit: any) =>
            exit
              .transition(defaultTransition)
              .style("opacity", 0)
              .remove()
          )
      )
      .attr("fill", "none")
      .attr("stroke", theme.text.colour)
      .attr("class", "line")
      .style("stroke-width", "2");
  }

  function arcTween(d: d3.PieArcDatum<LabelValue>, i: number, n: any) {
    let inter = d3.interpolate(n[i]._current, d);
    n[i]._current = inter(1);
    return function(t: number) {
      let newArc = inter(t);
      return d3.arc()(genDefaultArc(newArc));
    };
  }

  function findMidAngle(d: d3.PieArcDatum<LabelValue>) {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
  }

  function genDefaultArc(d: d3.PieArcDatum<LabelValue>): d3.DefaultArcObject {
    return {
      innerRadius: innerRadius,
      outerRadius: outerRadius,
      startAngle: d.startAngle,
      endAngle: d.endAngle
    };
  }

  function formatSlice(d: d3.PieArcDatum<LabelValue>): string {
    return `${d.data.label} | ${(
      ((d.endAngle - d.startAngle) / (Math.PI * 2)) *
      100
    ).toPrecision(3)}%`;
  }

  function getTextYPos(i: number, n: any, yLevels: number[]) {
    let yLevel = yLevels[i];
    let bounds = n[i].getBoundingClientRect();
    return yLevel + bounds.height * 0.2; // I don't know why this fits best
  }

  function getTextXPos(d: d3.PieArcDatum<LabelValue>, i: number, n: any) {
    let midAngle = findMidAngle(d);
    let multiplier: number = midAngle > Math.PI ? -1 : 1;

    let bounds = n[i].getBoundingClientRect();
    let offset =
      multiplier < 0 ? parentWidth * -0.45 : parentWidth * 0.45 - bounds.width;
    return offset;
  }

  function getLinePoints(
    i: number,
    centroids: centroid[],
    yLevels: number[]
  ): [number, number][] {
    const centroid = centroids[i];
    const nodes = legendSVG.selectAll("text").nodes()[i];
    const bounds = nodes.getBoundingClientRect();
    const multiplier: number = centroid.x < 0 ? -1 : 1;

    const textPointX =
      multiplier < 0
        ? parentWidth * -0.43 + bounds.width
        : parentWidth * 0.43 - bounds.width;
    if (
      (textPointX < centroid.x && multiplier > 0) ||
      (textPointX > centroid.x && multiplier < 0)
    ) {
      return [[centroid.x, centroid.y], [centroid.x, centroid.y]];
    }
    return [[centroid.x, centroid.y], [textPointX, yLevels[centroid.idx]]];
  }

  function getTooltipOffset(): [number, number] {
    let tooltipBox = tooltipSVG.node().getBBox();

    return [-tooltipBox.width / 2, tooltipBox.height / 2];
  }

  function getNewYLevels(centroids: centroid[]): number[] {
    let yLevels: number[] = [];
    const minDist: number =
      getTextBox(contentSVG, "test", FONT_SIZE).height * 2;
    const maxValue: number = parentHeight * 0.25;
    const minValue: number = -maxValue;

    centroids.sort((a: centroid, b: centroid) => a.y - b.y);
    centroids = distributeCentroids(centroids);
    centroids.forEach(centroid => {
      yLevels[centroid.idx] = centroid.y;
    });

    return yLevels;

    function distributeCentroids(centroids: centroid[]): centroid[] {
      let aboveCentroid = { x: 0, y: Number.NEGATIVE_INFINITY, idx: 0 };
      let centroid;
      let belowCentroid;
      centroids.push({ x: 0, y: Number.POSITIVE_INFINITY, idx: 0 });
      for (let i = 0; i < centroids.length - 1; i++) {
        centroid = centroids[i];
        belowCentroid = centroids[i + 1];
        if (centWithinY(centroid, belowCentroid)) {
          centroid.y = limitValue(belowCentroid.y - minDist);
        }
        if (centWithinY(aboveCentroid, centroid)) {
          centroid.y = aboveCentroid.y + minDist;
        }

        aboveCentroid = centroids[i];
      }
      centroids.pop();
      return centroids;
    }

    function centWithinY(a: centroid, b: centroid) {
      if (b.y - a.y <= minDist) {
        return true;
      }
    }

    function limitValue(value: number): number {
      return Math.min(Math.max(minValue, value), maxValue);
    }
  }
}
