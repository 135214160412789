const allConfig = {
  root: "",
  defaultPath: "/sales"
};

const localConfig = {};

const prodConfig = {};

interface IConfig {
  [key: string]: any;
  local: any;
  prod: any;
}

let config: IConfig = {
  all: allConfig,
  local: localConfig,
  prod: prodConfig
};

export default Object.assign(
  {},
  config.all,
  config[config.all.eng ? config.all.env : "dev"]
);
