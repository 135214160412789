import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  Button
} from "@material-ui/core";

const styles = createStyles({
  root: {
    flexGrow: 1,
    width: "100%"
  }
});
interface Props extends WithStyles<typeof styles> {
  signOut: () => void;
}

interface State {}

class GoogleSignout extends React.Component<Props, State> {
  render() {
    const { signOut } = this.props;
    return (
      <React.Fragment>
        <Button variant="outlined" onClick={signOut}>
          Sign Out
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(GoogleSignout);
