import * as d3 from "d3";

import { DrawData, WidgetData, TypeData } from "../../Interfaces";
import { DashTheme, geckoThemeDark } from "../../resources/themes";
import simpleText from "./simpleText";
import timePlot from "./timePlot";
import leaderboard from "./leaderboard";
import mapNorthAmerica from "./mapNorthAmerica";
import barChart from "./barChart";
import stackedBar from "./stackedBar";
import pieChart from "./pieChart";
import { limitText } from "./d3Utilities";

const dashboardTheme: DashTheme = geckoThemeDark;
const TOOLTIP_OPACITY = 0.4;
const TITLE_EM = "1.4em";

interface Props {
  widgetKey: string;
  widgetData: WidgetData;
  id: string;
  title: string;
  canCycle: boolean;
  cycleWidgetVersion: (key: string) => void;
}

const Draw = (props: Props, redraw: boolean) => {
  const {
    widgetData,
    id,
    title,
    canCycle,
    cycleWidgetVersion,
    widgetKey
  } = props;
  const { data, typeData, dateRange, tooltip } = widgetData;

  if (redraw) {
    d3.select(`#${id} > *`).remove();
  }

  let defaultTransition = d3
    .transition()
    .duration(redraw ? 0 : 500)
    .ease(d3.easePolyInOut);

  const rootNode: any = d3.select(`#${id}`).node();
  const bounds = rootNode.getBoundingClientRect();
  const w = Math.max(bounds.width || 0, 0);
  const h = Math.max(bounds.height || 0, 0);
  let svg: any;
  let titleSVG: any;
  let contentSVG: any;
  let cycleSVG: any;
  let infoSVG: any;

  svg = d3.select(`#${id} > svg`);
  if (svg.empty()) {
    svg = d3
      .select(`#${id}`)
      .append("svg")
      .style("display", "block")
      .attr("height", h)
      .attr("width", w)
      .attr("id", `svg-${id}`);
  }
  contentSVG = svg.select(`#${id} #content-${id}`);
  if (contentSVG.empty()) {
    contentSVG = svg.append("g").attr("id", `content-${id}`);
  }
  titleSVG = svg.select(`#${id} #title-${id}`);
  if (titleSVG.empty()) {
    titleSVG = svg
      .append("text")
      .attr("id", `title-${id}`)
      .attr("fill", dashboardTheme.text.colour);
  }
  cycleSVG = svg.select(`#${id} #next-${id}`);
  if (cycleSVG.empty()) {
    cycleSVG = svg
      .append("g")
      .attr("id", `next-${id}`)
      .attr("opacity", 0);
  }

  infoSVG = svg.select(`#${id} #tooltip-info-${id}`);
  if (infoSVG.empty()) {
    infoSVG = svg.append("g").attr("id", `tooltip-info-${id}`);
  }

  let getEm = svg
    .append("text")
    .text("test")
    .style("font-size", "1em");
  const em = getEm.node().getBoundingClientRect().height;
  getEm.remove();
  titleSVG
    .text(limitText(title, w * 0.9, TITLE_EM, contentSVG))
    .style("font-size", TITLE_EM);
  const titleSize = titleSVG.node().getBoundingClientRect();
  const titleHeight = titleSize.height;

  titleSVG.attr(
    "transform",
    `translate(${0.4 * titleHeight}, ${1 * titleHeight})`
  );

  let mouseenterFunctions: (() => void)[] = [];
  let mouseleaveFunctions: (() => void)[] = [];

  if (canCycle) {
    let cycleBox = cycleSVG.select("rect");
    if (cycleBox.empty()) {
      cycleBox = cycleSVG.append("rect");
    }
    let cycleText = cycleSVG.select("text");
    if (cycleText.empty()) {
      cycleText = cycleSVG
        .append("text")
        .text("More")
        .style("font-size", TITLE_EM)
        .attr("fill", dashboardTheme.text.colour);
    }

    let cycleBounds = cycleText.node().getBoundingClientRect();
    cycleBox
      .attr("width", cycleBounds.width * 1.2)
      .attr("height", cycleBounds.height)
      .attr("y", -cycleBounds.height * 0.8)
      .attr("x", -cycleBounds.width * 0.1)
      .attr("ry", cycleBounds.height * 0.15)
      .attr("rx", cycleBounds.height * 0.15)
      .attr("fill", dashboardTheme.widget.lineSecondary);
    cycleSVG
      .on("click", () => {
        cycleWidgetVersion(widgetKey);
        cycleBox
          .attr("fill", dashboardTheme.text.colour)
          .transition(defaultTransition)
          .attr("fill", dashboardTheme.widget.lineSecondary);
      })

      .on("mouseenter", () => {
        cycleSVG.attr("opacity", 1);
      })
      .on("mouseover", () => {
        cycleSVG.attr("opacity", 1);
      })
      .on("mouseout", () => {
        cycleSVG.attr("opacity", TOOLTIP_OPACITY);
      })
      .attr(
        "transform",
        `translate(${0.4 * titleHeight}, ${h - 0.5 * titleHeight})`
      );

    mouseenterFunctions.push(() => {
      cycleSVG.attr("opacity", TOOLTIP_OPACITY).style("cursor", "pointer");
    });
    mouseleaveFunctions.push(() => {
      cycleSVG.attr("opacity", 0).style("cursor", "default");
    });
  }

  if (tooltip !== undefined) {
    let { linedText, lineWidth } = boxText(w * 0.8, tooltip);

    let infoIcon = infoSVG.select(`#${id} #info-icon-${id}`);
    if (infoIcon.empty()) {
      infoIcon = infoSVG
        .append("g")
        .attr("id", `info-icon-${id}`)
        .attr(
          "transform",
          `translate(${w - titleHeight}, ${1.5 * titleHeight})`
        )
        .attr("opacity", 0);
    }

    let tooltipSVG = infoSVG.select(`#${id} #tooltipSVG-${id}`);
    if (tooltipSVG.empty()) {
      tooltipSVG = infoSVG
        .append("g")
        .attr("id", `tooltipSVG-${id}`)
        .attr("pointer-events", "none")
        .attr("opacity", 1);
    }

    let textData = linedText.split("\n");

    tooltipSVG.attr("opacity", 0);

    let tooltipRect = tooltipSVG
      .selectAll("rect")
      .data([lineWidth], (d: number) => d);
    tooltipRect
      .enter()
      .append("rect")
      .attr("width", (d: number) => d + 0.6 * em)
      .attr("height", textData.length * em + em * 0.5) //magic ratio
      .attr("y", -em)
      .attr("x", -0.3 * em)
      .attr("ry", em * 0.15)
      .attr("rx", em * 0.15)
      .attr("fill", dashboardTheme.root.background);
    tooltipRect.exit().remove();

    let tooltipText = tooltipSVG
      .selectAll("text")
      .data(textData, (d: string, i: number) => d + i);

    tooltipText
      .enter()
      .append("text")
      .text((d: string) => d)
      .attr("y", (d: string, i: number) => i * em)
      .attr("fill", dashboardTheme.text.colour);

    tooltipText.exit().remove();

    infoIcon
      .on("mouseenter", () => {
        let cursor = d3.mouse(svg.node());
        infoIcon.attr("opacity", 1);
        tooltipSVG
          .attr("opacity", 1)
          .attr(
            "transform",
            `translate(${cursor[0] - lineWidth}, ${cursor[1] + em})`
          );
      })
      .on("mouseover", () => {
        infoIcon.attr("opacity", 1);
        tooltipSVG.attr("opacity", 1);
      })
      .on("mouseout", () => {
        infoIcon.attr("opacity", TOOLTIP_OPACITY);
        tooltipSVG.attr("opacity", 0);
      });

    let infoIconCircle = infoIcon.select("circle");
    if (infoIconCircle.empty()) {
      infoIconCircle = infoIcon.append("circle");
    }
    let infoIconText = infoIcon.select("text");
    if (infoIconText.empty()) {
      infoIconText = infoIcon
        .append("text")
        .style("font-size", TITLE_EM)
        .attr("fill", dashboardTheme.text.colour)
        .text("i")
        .attr("text-anchor", "middle");
    }
    let infoNoticeBounds = infoIconText.node().getBoundingClientRect();
    infoIconCircle
      .attr("r", infoNoticeBounds.height * 0.6)
      .attr("cy", -infoNoticeBounds.height * 0.3)
      .attr("fill", dashboardTheme.widget.lineSecondary);

    mouseenterFunctions.push(() => {
      infoIcon.attr("opacity", TOOLTIP_OPACITY).style("cursor", "pointer");
    });
    mouseleaveFunctions.push(() => {
      infoIcon.attr("opacity", 0).style("cursor", "default");
    });
  }

  svg
    .on("mouseenter", () => {
      mouseenterFunctions.forEach(fn => {
        fn();
      });
    })
    .on("mouseleave", () => {
      mouseleaveFunctions.forEach(fn => {
        fn();
      });
    });

  if (typeData !== undefined) {
    let drawData: DrawData = {
      data: data,
      dateRange: dateRange,
      displayProperties: {
        id: id,
        parentWidth: w,
        parentHeight: h,
        contentSVG: contentSVG,
        defaultTransition: defaultTransition,
        theme: dashboardTheme,
        titleSize: titleSize
      }
    };

    drawType(typeData, drawData);
  }

  function boxText(width: number, text: string, fontSize?: string) {
    let textArray: string[] = text.split(" ");

    let lineWidth: number = 0;
    let maxWidth: number = 0;
    let newTextArray: string[] = [];
    if (fontSize === undefined) {
      fontSize = "1em";
    }
    let d3Text = svg.append("text").attr("font-size", fontSize);
    const spaceWidth =
      d3Text
        .text("_ _")
        .node()
        .getComputedTextLength() -
      d3Text
        .text("__")
        .node()
        .getComputedTextLength(); //dumb hack to calculate space width
    for (let i = 0; i < textArray.length; i++) {
      let itemLength =
        d3Text
          .text(textArray[i] + " ")
          .node()
          .getComputedTextLength() + spaceWidth;
      if (itemLength + lineWidth < width) {
        newTextArray.push(textArray[i]);
        lineWidth += itemLength;
      } else {
        newTextArray.push("\n");
        newTextArray.push(textArray[i]);
        lineWidth = itemLength;
      }
      if (lineWidth > maxWidth) {
        maxWidth = lineWidth;
      }
    }
    d3Text.remove();
    let boxData = { linedText: newTextArray.join(" "), lineWidth: maxWidth };
    return boxData;
  }
};

function drawType(typeData: TypeData, drawData: DrawData) {
  switch (typeData.type) {
    case "simpleText":
      simpleText(drawData, typeData);
      break;
    case "mapNorthAmerica":
      mapNorthAmerica(drawData, typeData);
      break;
    case "timePlot":
      timePlot(drawData, typeData);
      break;
    case "leaderboard":
      leaderboard(drawData, typeData);
      break;
    case "barChart":
      barChart(drawData, typeData);
      break;
    case "stackedBar":
      stackedBar(drawData, typeData);
      break;
    case "pieChart":
      pieChart(drawData, typeData);
      break;
    default:
      simpleText(drawData, typeData);
      break;
  }
}

export default Draw;
