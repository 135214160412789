import { WidgetMap } from "../../../Interfaces";

export var widgets: WidgetMap = {
  northAmericaVisitors: [
    {
      title: "Today's Activity",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [
            { name: "ga:longitude" },
            { name: "ga:latitude" },
            { name: "ga:city" }
          ],
          orderBys: [],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "mapNorthAmerica",
          display: "points"
        },
        tooltip:
          "Shows the number of sessions (visits at least 30 mins apart) today from any city"
      }
    },
    {
      title: "7 Day Activity",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [
            { name: "ga:longitude" },
            { name: "ga:latitude" },
            { name: "ga:city" }
          ],
          orderBys: [],
          dateRanges: [
            {
              startDate: "7daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "mapNorthAmerica",
          display: "points"
        },
        tooltip:
          "Shows the number of sessions (visits at least 30 mins apart) over the last 7 days from any city"
      }
    },
    {
      title: "Monthly Activity",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [
            { name: "ga:longitude" },
            { name: "ga:latitude" },
            { name: "ga:city" }
          ],
          orderBys: [],
          dateRanges: [
            {
              startDate: "30daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "mapNorthAmerica",
          display: "points"
        },
        tooltip:
          "Shows the number of sessions (visits at least 30 mins apart) over the last 30 days from any city"
      }
    },
    {
      title: "Since Launch Activity",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [
            { name: "ga:longitude" },
            { name: "ga:latitude" },
            { name: "ga:city" }
          ],
          orderBys: [],
          dateRanges: [
            {
              startDate: "2019-07-25",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "mapNorthAmerica",
          display: "points"
        },
        tooltip: "Launched on July 25, 2019"
      }
    }
  ],
  loginsOverTime: [
    {
      title: "Hourly Visits",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:date" }, { name: "ga:hour" }],
          orderBys: [{ fieldName: "ga:hour", sortOrder: "ASCENDING" }],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "timePlot",
          timeUnit: "hours",
          timePeriod: "days",
          display: "bar"
        },
        tooltip:
          "Visits are counted as any usage of the site at least 30 mins after any previous activity"
      }
    },
    {
      title: "Daily Visits",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:date" }],
          orderBys: [{ fieldName: "ga:date", sortOrder: "ASCENDING" }],
          dateRanges: [
            {
              startDate: "6daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "timePlot",
          timeUnit: "days",
          timePeriod: "weeks",
          display: "line"
        },
        tooltip:
          "Visits are counted as any usage of the site at least 30 mins after any previous activity"
      }
    },
    {
      title: "Weekly Visits",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:date" }],
          orderBys: [{ fieldName: "ga:date", sortOrder: "ASCENDING" }],
          dateRanges: [
            {
              startDate: "29daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "timePlot",
          timeUnit: "weeks",
          timePeriod: "months",
          display: "line"
        },
        tooltip:
          "Visits are counted as any usage of the site at least 30 mins after any previous activity"
      }
    },
    {
      title: "Monthly Visits",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:date" }],
          orderBys: [{ fieldName: "ga:date", sortOrder: "ASCENDING" }],
          dateRanges: [
            {
              startDate: "13monthsAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "timePlot",
          timeUnit: "months",
          timePeriod: "years",
          display: "line"
        },
        tooltip:
          "Visits are counted as any usage of the site at least 30 mins after any previous activity"
      }
    }
  ],

  numContracts: [
    {
      title: "Experience Contracts - Today",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getCreatedContractTotals",
          dimensions: [{ name: "total_contracts_digital" }],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          // suffix: "",
          defaultValue: "0"
        },
        tooltip:
          "The number of contracts created through the Nutrien Experience Sales App."
      }
    },
    {
      title: "Experience Contracts - 7 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getCreatedContractTotals",
          dimensions: [{ name: "total_contracts_digital" }],
          dateRanges: [
            {
              startDate: "6daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          // suffix: "",
          defaultValue: "0"
        },
        tooltip:
          "The number of contracts created through the Nutrien Experience Sales App."
      }
    },
    {
      title: "Experience Contracts - 30 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getCreatedContractTotals",
          dimensions: [{ name: "total_contracts_digital" }],
          dateRanges: [
            {
              startDate: "29daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          // suffix: "",
          defaultValue: "0"
        },
        tooltip:
          "The number of contracts created through the Nutrien Experience Sales App."
      }
    },
    {
      title: "Experience Contracts - Since Launch",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getCreatedContractTotals",
          dimensions: [{ name: "total_contracts_digital" }],
          dateRanges: [
            {
              startDate: "2019-07-25",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          // suffix: "",
          defaultValue: "0"
        },
        tooltip: "Launched on July 25, 2019"
      }
    }
  ],
  timeSavedTicker: [
    {
      title: "Total Time Saved",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [
            { expression: "ga:totalEvents" },
            { expression: "ga:avgEventValue" }
          ],
          dimensions: [{ name: "ga:deviceCategory" }],
          filters: "ga:eventAction==Contract Created",
          orderBys: [],
          dateRanges: [
            {
              startDate: "2019-07-25",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          modifier: 1,
          format: "time"
        },
        tooltip:
          "Calculated based on the number of contracts, and the difference between contract creation time in NXP and estimated contract creation time in SAP. Full formula per contract: 2.5 mins average SAP entry time - average NXP entry time + 30 mins saved travel if on mobile"
      }
    }
  ],
  salesUsers: [
    {
      title: "Sales Reps - Today",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractTotalsPerSalesRepByDateRange",
          dimensions: [{ name: "sales_rep_name" }],
          filters: { from_exp_digital_app: "Y" },
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Number of Experience contracts each sales rep has created"
      }
    },
    {
      title: "Sales Reps - 7 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractTotalsPerSalesRepByDateRange",
          dimensions: [{ name: "sales_rep_name" }],
          filters: { from_exp_digital_app: "Y" },
          dateRanges: [
            {
              startDate: "6daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Number of Experience contracts each sales rep has created"
      }
    },

    {
      title: "Sales Reps - 30 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractTotalsPerSalesRepByDateRange",
          dimensions: [{ name: "sales_rep_name" }],
          filters: { from_exp_digital_app: "Y" },
          dateRanges: [
            {
              startDate: "29daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Number of Experience contracts each sales rep has created"
      }
    },
    {
      title: "Sales Reps - Since Launch",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractTotalsPerSalesRepByDateRange",
          dimensions: [{ name: "sales_rep_name" }],
          filters: { from_exp_digital_app: "Y" },
          dateRanges: [
            {
              startDate: "2019-07-25",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Launched on July 25, 2019"
      }
    }
  ],

  contractCompare: [
    {
      title: "Contracts Created - Today",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getCreatedContractTotals",
          dimensions: [
            { name: "total_contracts_digital" },
            { name: "total_contracts" }
          ],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "stackedBar"
        },
        tooltip:
          "Number of contracts created in each system over the last 7 days."
      }
    },
    {
      title: "Contracts Created - 7 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getCreatedContractTotals",
          dimensions: [
            { name: "total_contracts_digital" },
            { name: "total_contracts" }
          ],
          dateRanges: [
            {
              startDate: "6daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "stackedBar"
        },
        tooltip:
          "Number of contracts created in each system over the last 7 days."
      }
    },
    {
      title: "Contracts Created - 30 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getCreatedContractTotals",
          dimensions: [
            { name: "total_contracts_digital" },
            { name: "total_contracts" }
          ],
          dateRanges: [
            {
              startDate: "29daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "stackedBar"
        },
        tooltip:
          "Number of contracts created in each system over the last 30 days."
      }
    },
    {
      title: "Contracts Created - Since Launch",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getCreatedContractTotals",
          dimensions: [
            { name: "total_contracts_digital" },
            { name: "total_contracts" }
          ],
          dateRanges: [
            {
              startDate: "2019-07-25",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "stackedBar"
        },
        tooltip: "Launched on July 25, 2019"
      }
    }
  ],
  contractVolume: [
    {
      title: "Contract Volumes (MT) - Today",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getTotalVolumeMTByDateRange",
          dimensions: [
            { name: "total_volume_mt_digital" },
            { name: "total_volume_mt_non_digital" }
          ],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "stackedBar"
        },
        tooltip: "Volumes involved with all contracts in each system."
      }
    },
    {
      title: "Contract Volumes (MT) - 7 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getTotalVolumeMTByDateRange",
          dimensions: [
            { name: "total_volume_mt_digital" },
            { name: "total_volume_mt_non_digital" }
          ],
          dateRanges: [
            {
              startDate: "6daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "stackedBar"
        },
        tooltip: "Volumes involved with all contracts in each system."
      }
    },
    {
      title: "Contract Volumes (MT) - 30 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getTotalVolumeMTByDateRange",
          dimensions: [
            { name: "total_volume_mt_digital" },
            { name: "total_volume_mt_non_digital" }
          ],
          dateRanges: [
            {
              startDate: "29daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "stackedBar"
        },
        tooltip: "Volumes involved with all contracts in each system."
      }
    },
    {
      title: "Contract Volumes (MT) - Since Launch",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getTotalVolumeMTByDateRange",
          dimensions: [
            { name: "total_volume_mt_digital" },
            { name: "total_volume_mt_non_digital" }
          ],
          dateRanges: [
            {
              startDate: "2019-07-25",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "stackedBar"
        },
        tooltip: "Launched on July 25, 2019"
      }
    }
  ],
  contractActions: [
    {
      title: "Contract Actions - Today",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractActionsTotalsByDateRange",
          dimensions: [
            { name: "total_approved_digital" },
            { name: "total_rejected_digital" },
            { name: "total_approved_zvsm" },
            { name: "total_rejected_zvsm" }
          ],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Contract actions performed through the experience platform"
      }
    },
    {
      title: "Contract Actions - Past 7 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractActionsTotalsByDateRange",
          dimensions: [
            { name: "total_approved_digital" },
            { name: "total_rejected_digital" },
            { name: "total_approved_zvsm" },
            { name: "total_rejected_zvsm" }
          ],
          dateRanges: [
            {
              startDate: "6daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Contract actions performed through the experience platform"
      }
    },
    {
      title: "Contract Actions - Past 30 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractActionsTotalsByDateRange",
          dimensions: [
            { name: "total_approved_digital" },
            { name: "total_rejected_digital" },
            { name: "total_approved_zvsm" },
            { name: "total_rejected_zvsm" }
          ],
          dateRanges: [
            {
              startDate: "29daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Contract actions performed through the experience platform"
      }
    },
    {
      title: "Contract Actions - Since Launch",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractActionsTotalsByDateRange",
          dimensions: [
            { name: "total_approved_digital" },
            { name: "total_rejected_digital" },
            { name: "total_approved_zvsm" },
            { name: "total_rejected_zvsm" }
          ],
          dateRanges: [
            {
              startDate: "2019-07-25",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Contract actions performed through the experience platform"
      }
    }
  ],
  activeUsers: [
    {
      title: "Visitors Today",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:Users" }],
          dimensions: [{ name: "ga:nthDay" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          defaultValue: "0",
          trendline: {
            active: false,
            summation: false
          }
        },
        tooltip:
          "Visitor uniqueness is tracked by browser cookies, so clearing your local storage or visiting from a new device will appear as a new visitor"
      }
    },
    {
      title: "Visitors - 7 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:7dayUsers" }],
          dimensions: [{ name: "ga:nthDay" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "7daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          defaultValue: "0",
          trendline: {
            active: false,
            summation: false
          }
        },
        tooltip:
          "Visitor uniqueness is tracked by browser cookies, so clearing your local storage or visiting from a new device will appear as a new visitor"
      }
    },
    {
      title: "Visitors - 30 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:30DayUsers" }],
          dimensions: [{ name: "ga:nthDay" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "30daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          defaultValue: "0",
          trendline: {
            active: false,
            summation: false
          }
        },
        tooltip:
          "Visitor uniqueness is tracked by browser cookies, so clearing your local storage or visiting from a new device will appear as a new visitor"
      }
    },
    {
      title: "Total Unique Visitors",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:users" }],
          dimensions: [],
          orderBys: [],
          dateRanges: [
            {
              startDate: "2019-07-25",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "simpleText",
          defaultValue: "0",
          trendline: {
            active: false,
            summation: false
          }
        },
        tooltip:
          "Visitor uniqueness is tracked by browser cookies, so clearing your local storage or visiting from a new device will appear as a new visitor"
      }
    }
  ],

  deviceCategory: [
    {
      title: "Devices Today",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:deviceCategory" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "pieChart"
        },
        tooltip:
          "Ratio of device category from any visit at least 30 mins after a previous visit"
      }
    },
    {
      title: "Devices - 7 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:deviceCategory" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "7daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "pieChart"
        },
        tooltip:
          "Ratio of device category from any visit at least 30 mins after a previous visit"
      }
    },

    {
      title: "Devices - 30 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:deviceCategory" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "30daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "pieChart"
        },
        tooltip:
          "Ratio of device category from any visit at least 30 mins after a previous visit"
      }
    },
    {
      title: "Devices - Since Launch",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:sessions" }],
          dimensions: [{ name: "ga:deviceCategory" }],
          orderBys: [],
          dateRanges: [
            {
              startDate: "2019-07-25",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "pieChart"
        },
        tooltip: "Launched on July 25, 2019"
      }
    }
  ],
  salesRegions: [
    {
      title: "Sales Regions - Today",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractTotalsPerSalesRegionByDateRange",
          dimensions: [{ name: "sales_region" }],
          filters: { from_exp_digital_app: "Y" },
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Number of Experience contracts each sales region has created"
      }
    },
    {
      title: "Sales Regions - 7 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractTotalsPerSalesRegionByDateRange",
          dimensions: [{ name: "sales_region" }],
          filters: { from_exp_digital_app: "Y" },
          dateRanges: [
            {
              startDate: "6daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Number of Experience contracts each sales region has created"
      }
    },

    {
      title: "Sales Regions - 30 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractTotalsPerSalesRegionByDateRange",
          dimensions: [{ name: "sales_region" }],
          filters: { from_exp_digital_app: "Y" },
          dateRanges: [
            {
              startDate: "29daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Number of Experience contracts each sales region has created"
      }
    },
    {
      title: "Sales Regions - Since Launch",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractTotalsPerSalesRegionByDateRange",
          dimensions: [{ name: "sales_region" }],
          filters: { from_exp_digital_app: "Y" },
          dateRanges: [
            {
              startDate: "2019-07-25",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Launched on July 25, 2019"
      }
    }
  ],
  salesGroups: [
    {
      title: "Sales Groups - Today",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractTotalsPerSalesGroupByDateRange",
          dimensions: [{ name: "sales_group_name" }],
          filters: { from_exp_digital_app: "Y" },
          dateRanges: [
            {
              startDate: "today",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Number of Experience contracts each sales group has created"
      }
    },
    {
      title: "Sales Groups - 7 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractTotalsPerSalesGroupByDateRange",
          dimensions: [{ name: "sales_group_name" }],
          filters: { from_exp_digital_app: "Y" },
          dateRanges: [
            {
              startDate: "6daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Number of Experience contracts each sales group has created"
      }
    },
    {
      title: "Sales Groups - 30 days",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractTotalsPerSalesGroupByDateRange",
          dimensions: [{ name: "sales_group_name" }],
          filters: { from_exp_digital_app: "Y" },
          dateRanges: [
            {
              startDate: "29daysAgo",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Number of Experience contracts each sales group has created"
      }
    },
    {
      title: "Sales Groups - Since Launch",
      query: {
        refreshInterval: 1200000,
        parameters: {
          endpointType: "getContractTotalsPerSalesGroupByDateRange",
          dimensions: [{ name: "sales_group_name" }],
          filters: { from_exp_digital_app: "Y" },
          dateRanges: [
            {
              startDate: "2019-07-25",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        },
        tooltip: "Launched on July 25, 2019"
      }
    }
  ],
  leaderboardDevices: [
    {
      title: "Mobile Devices",
      query: {
        refreshInterval: 1200000,
        parameters: {
          metrics: [{ expression: "ga:users" }],
          dimensions: [{ name: "ga:mobileDeviceModel" }],
          orderBys: [{ fieldName: "ga:users", sortOrder: "DESCENDING" }],
          dateRanges: [
            {
              startDate: "2019-07-25",
              endDate: "today"
            }
          ]
        }
      },
      widgetData: {
        typeData: {
          type: "leaderboard"
        }
      }
    }
  ]
};

// export default widgets,
