import React from "react";
import Dashboard from "../../components/organisms/Dashboard";
import { widgets } from "./config/widgets";
import { layoutConfig } from "./config/layout";

// const VIEW_ID: string = "198406679"; // prod
// const VIEW_ID: string = "196744401"; // dev
// const VIEW_ID: string = "198409959"; // staging
const VIEW_ID: string = "202407570"; // warehouse prod

interface Props {
  signOut: () => void;
}
interface State {}

class WarehouseApp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { signOut } = this.props;
    return (
      <React.Fragment>
        <Dashboard
          analyticsId={VIEW_ID}
          widgets={widgets}
          layoutConfig={layoutConfig}
          signOut={signOut}
        />
      </React.Fragment>
    );
  }
}

export default WarehouseApp;
