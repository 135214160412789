import React from "react";
import { Route, Redirect, RouteProps } from "react-router";
import config from "../../../config";

const LOGIN_PATH = config.root + "/login";

interface PrivateRouteProps extends RouteProps {
  component: any;
  isSignedIn: boolean;
  // callback: () => void;
  // [propName: string]: {};
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, isSignedIn, ...rest } = props;

  return (
    <Route
      {...rest}
      render={routeProps =>
        isSignedIn ? (
          <Component {...routeProps} {...rest} />
        ) : (
          <Redirect
            to={{ pathname: LOGIN_PATH, state: { from: routeProps.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
