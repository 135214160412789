import React from "react";
import Login from "../../components/organisms/Login";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";
import PrivateRoute from "../../components/molecules/PrivateRoute";
import SalesApp from "../SalesApp";
import WarehouseApp from "../WarehouseApp";
import config from "../../config";

const DEFAULT_PATH = config.root + config.defaultPath;

interface Props {}
interface State {
  user: any;
}

class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: undefined
    };
    this.loginSuccess = this.loginSuccess.bind(this);
    this.loginFailure = this.loginFailure.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  loginSuccess(googleUser: any) {
    this.setState({ user: googleUser });
  }

  loginFailure(reason: { error: string }) {
    console.log(reason.error);
    this.setState({ user: undefined });
  }

  async signOut() {
    const { user } = this.state;
    if (user != null) {
      await user.disconnect();
    }
    this.setState({ user: user });
  }

  render() {
    const { user } = this.state;
    const authenticated = user && user.isSignedIn();
    //@ts-ignore
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/login"
            render={props => (
              <Login
                {...props}
                isSignedIn={authenticated}
                onSuccess={this.loginSuccess}
                onFailure={this.loginFailure}
              />
            )}
          />
          //@ts-ignore
          <PrivateRoute
            path="/sales"
            isSignedIn={authenticated}
            component={SalesApp}
            signOut={this.signOut}
          />
          //@ts-ignore
          <PrivateRoute
            path="/warehouse"
            isSignedIn={authenticated}
            component={WarehouseApp}
            signOut={this.signOut}
          />
          <Redirect to={DEFAULT_PATH} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Home;
