import { QueryParams, LiveQueryParams, DateRange } from "../../Interfaces";

/* globals gapi */

const PATH: string = "/v4/reports:batchGet";
const ROOT: string = "https://analyticsreporting.googleapis.com";
const LIVEPATH: string =
  "https://www.googleapis.com/analytics/v3/data/realtime";
const WEEKREGEX = /^[1-9]\d*weeksAgo$/;
const MONTHREGEX = /^[1-9]\d*monthsAgo$/;
const YEARREGEX = /^[1-9]\d*yearsAgo$/;
const DIGITREGEX = /^[1-9]\d*/;
const CACHE_DURATION = 20 * 60 * 1000;

class GoogleAnalytics {
  private viewId: string;
  private cacheName: string;
  constructor(viewId: string) {
    this.viewId = viewId;
    this.cacheName = viewId + "_CACHE";
    this.localizeDateRanges = this.localizeDateRanges.bind(this);
    this.getFromCache = this.getFromCache.bind(this);
    this.initCache = this.initCache.bind(this);
    this.initCache();
    // this.cache = Promise.resolve(this.initCache());
  }

  initCache() {
    caches.delete(this.cacheName);
    setInterval(() => {
      console.log("purging cache", this.cacheName);
      caches.delete(this.cacheName);
    }, CACHE_DURATION);
  }

  async getFromCache(
    query: QueryParams | LiveQueryParams
  ): Promise<Response | undefined> {
    let request: Request = new Request(JSON.stringify(query));
    return caches
      .open(this.cacheName)
      .then((cache: Cache) => cache.match(request));
  }

  async saveInCache(query: QueryParams | LiveQueryParams, response: Response) {
    let request: Request = new Request(JSON.stringify(query));
    caches
      .open(this.cacheName)
      .then((cache: Cache) => cache.put(request, response));
  }

  async queryAnalytics(queryData: QueryParams): Promise<Response> {
    // console.log("querying", queryData);
    let cacheResult: Response | undefined = await this.getFromCache(queryData);

    return new Promise(async resolve => {
      if (cacheResult === undefined) {
        console.log("not in cache");
        const { metrics, dimensions, orderBys, filters } = queryData;
        let dateRanges = this.localizeDateRanges(queryData.dateRanges)[0];
        let gapiResponse: gapi.client.HttpRequestFulfilled<
          any
        > | void = await gapi.client.request({
          path: ROOT + PATH,
          method: "POST",
          body: {
            reportRequests: [
              {
                viewId: this.viewId,
                dateRanges: dateRanges,
                metrics: metrics,
                dimensions: dimensions,
                orderBys: orderBys,
                filtersExpression: filters
              }
            ]
          }
        });
        let stringed: string = JSON.stringify(gapiResponse.result);
        let result: Response = new Response(stringed);
        this.saveInCache(queryData, result.clone());
        resolve(result);
        return;
      } else {
        // console.log("found in cache");
        resolve(cacheResult);
        return;
      }
    });
  }

  async queryLiveAnalytics(queryData: LiveQueryParams): Promise<Response> {
    // console.log("querying live", queryData);
    let cacheResult: Response | undefined = await this.getFromCache(queryData);
    return new Promise(async resolve => {
      if (cacheResult === undefined) {
        console.log("not in cache");
        const { metrics, dimensions, sort } = queryData;
        let gapiResponse: gapi.client.HttpRequestFulfilled<
          any
        > | void = await gapi.client.request({
          path: LIVEPATH,
          method: "GET",
          params: {
            ids: "ga:" + this.viewId,
            metrics: metrics,
            dimensions: dimensions,
            sort: sort
          }
        });
        let stringed: string = JSON.stringify(gapiResponse.result);
        let result: Response = new Response(stringed);
        this.saveInCache(queryData, result.clone());
        resolve(result);
        return;
      } else {
        // console.log("found in cache");
        resolve(cacheResult);
        return;
      }
    });
  }

  localizeDateRanges(dateRanges: DateRange[]): DateRange[] {
    let newRanges: DateRange[] = [];
    for (let i = 0; i < dateRanges.length; i++) {
      let convertedStart = this.localizeDate(dateRanges[i].startDate);
      let convertedEnd = this.localizeDate(dateRanges[i].endDate);
      newRanges.push({ startDate: convertedStart, endDate: convertedEnd });
    }
    return newRanges;
  }

  localizeDate(dateString: string): string {
    let quantity: number = 0;
    let digitRegexMatch: any;
    let today: Date = new Date();
    let days: number = 0;
    if (!!dateString.match(WEEKREGEX)) {
      digitRegexMatch = dateString.match(DIGITREGEX);
      if (!!digitRegexMatch) {
        quantity = +digitRegexMatch[0];
        days += today.getDay();
        days += (quantity - 1) * 7;
        return `${days}daysAgo`;
      }
    } else if (!!dateString.match(MONTHREGEX)) {
      digitRegexMatch = dateString.match(DIGITREGEX);
      if (!!digitRegexMatch) {
        quantity = +digitRegexMatch[0];
        days += today.getDate();
        days += (quantity - 1) * 30;
        return `${days}daysAgo`;
      }
    } else if (!!dateString.match(YEARREGEX)) {
      digitRegexMatch = dateString.match(DIGITREGEX);
      if (!!digitRegexMatch) {
        quantity = +digitRegexMatch[0];
        days += today.getDate();
        days += today.getMonth() * 30;
        days += (quantity - 1) * 365;
        return `${days}daysAgo`;
      }
    }
    return dateString;
  }
}

export default GoogleAnalytics;
