export interface DashTheme {
  root: RootTheme;
  wrapper: WrapperTheme;
  paper: PaperTheme;
  text: TextTheme;
  widget: WidgetTheme;
}

interface RootTheme {
  background: string;
  gridgap: string;
}
interface WrapperTheme {
  fib: boolean;
}

interface PaperTheme {
  cornerRadius: string;
  background: string;
}

interface TextTheme {
  colour: string;
  fontFamily: string;
}

interface WidgetTheme {
  linePrimary: string;
  lineSecondary: string;
}

export var geckoThemeDark: DashTheme = {
  root: {
    background: "#101214",
    gridgap: "0.5em"
  },
  wrapper: {
    fib: true
  },
  paper: {
    cornerRadius: "0px",
    background: "#2a2a2a"
  },
  text: {
    colour: "#FFF",
    fontFamily: "'Roboto', sans-serif;"
  },
  widget: {
    // linePrimary: "#3EB4F9",
    // linePrimary: "#85d503",
    linePrimary: "#97D700",
    lineSecondary: "#4a4a4a"
  }
};
