import { Tile } from "../../../Interfaces";

export var layoutConfig: Tile[] = [
  { key: "northAmericaVisitors", row: 1, col: 1, width: 11, height: 16 },
  { key: "loginsOverTime", row: 10, col: 12, width: 9, height: 7 },
  { key: "activeUsers", row: 1, col: 12, width: 4, height: 4 },
  { key: "deviceCategory", row: 1, col: 16, width: 5, height: 9 },
  { key: "leaderboardDevices", row: 5, col: 12, width: 4, height: 5 }
];

export var layout: number[][] = [
  [1, 1, 1, 2, 3],
  [1, 1, 1, 8, 8],
  [5, 5, 4, 6, 7]
];
