import React from "react";
/* global gapi */

const GAPI_NAME: string = "dxp-signin2";
interface Props {
  onSuccess: (googleUser: any) => void;
  onFailure: (reason: { error: string }) => void;
}

interface State {}

class GoogleSignin extends React.Component<Props, State> {
  componentDidMount() {
    const { onSuccess, onFailure } = this.props;
    gapi.signin2.render(GAPI_NAME, {
      //   scope: "https://www.googleapis.com/auth/plus.login",
      onsuccess: onSuccess,
      onfailure: onFailure
    });
  }

  render() {
    return (
      <React.Fragment>
        <div id={GAPI_NAME} />
      </React.Fragment>
    );
  }
}

export default GoogleSignin;
