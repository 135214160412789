import React from "react";
import GoogleSignin from "../../atoms/GoogleSignin";
import { RouteProps, Redirect, Route } from "react-router";
import config from "../../../config";

const DEFAULT_PATH = config.root + config.defaultPath;

interface Props extends RouteProps {
  isSignedIn: boolean;
  onSuccess: (googleUser: any) => void;
  onFailure: (reason: { error: string }) => void;
}

const Login = (props: Props) => {
  const { onSuccess, onFailure, isSignedIn, ...rest } = props;
  const { from } = (props.location || { state: undefined }).state || {
    from: { pathname: DEFAULT_PATH }
  };

  return (
    <Route
      {...rest}
      render={routeProps =>
        isSignedIn ? (
          <Redirect to={from} />
        ) : (
          <React.Fragment>
            Log in to continue
            <GoogleSignin onSuccess={onSuccess} onFailure={onFailure} />
          </React.Fragment>
        )
      }
    />
  );
};

export default Login;
