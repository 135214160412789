import * as d3 from "d3";
import { DrawData, DateValue, TimePlotMeta } from "../../../Interfaces";
import { interpolatePath } from "d3-interpolate-path";

const MAX_TIMESTAMP = 8640000000000000;
const DEFAULT_OPACITY = 0.7;
const MOUSEOVER_POINT_OPACITY = 0.9;
const DEFAULT_RADIUS = "0.5vh";
const ZERO_RADIUS = "0vh";
const GRAPH_BOTTOM = 0.85;
const GRAPH_TOP = 0.2;
const GRAPH_SIDE = 0.1;

export default function timePlot(
  drawData: DrawData,
  timePlotMeta: TimePlotMeta
) {
  const { displayProperties } = drawData;
  let data = drawData.data;
  const { timePeriod, timeUnit } = timePlotMeta;
  const {
    id,
    parentWidth,
    parentHeight,
    contentSVG,
    defaultTransition,
    theme
  } = displayProperties;

  let xAxisSVG, yAxisSVG, plotSVG, tooltip: any;

  plotSVG = contentSVG.select(`#${id} #plot-${id}`);
  if (plotSVG.empty()) {
    plotSVG = contentSVG.append("g").attr("id", `plot-${id}`);
  }
  xAxisSVG = contentSVG.select(`#${id} #x-${id}`);
  if (xAxisSVG.empty()) {
    xAxisSVG = contentSVG.append("g").attr("id", `x-${id}`);
  }
  yAxisSVG = contentSVG.select(`#${id} #y-${id}`);
  if (yAxisSVG.empty()) {
    yAxisSVG = contentSVG.append("g").attr("id", `y-${id}`);
  }
  tooltip = contentSVG.select(`#${id} #tooltip-${id}`);
  if (tooltip.empty()) {
    tooltip = contentSVG
      .append("text")
      .attr("class", "tooltip")
      .attr("id", `tooltip-${id}`)
      .style("fill", theme.text.colour)
      .attr("pointer-events", "none")
      .style("opacity", 0)
      .attr(
        "transform",
        `translate(${parentWidth * GRAPH_TOP}, ${parentHeight * GRAPH_SIDE})`
      );
  }

  let d3TimePeriod: d3.CountableTimeInterval = getCountableTime(timePeriod);
  let d3TimeUnit: d3.CountableTimeInterval = getCountableTime(timeUnit);
  let timeFormat = getTimeFormat(timeUnit);

  let now: Date = d3TimeUnit.ceil(new Date());
  if (!data || data.length === 0) {
    data = [
      {
        dimensions: [now.toISOString().substring(0, 10), 0],
        metrics: [{ values: [0] }]
      }
    ];
  }

  let startDate: Date;

  startDate = d3TimePeriod.offset(now, -1);

  let dateValues: DateValue[] = [];
  for (let i = 0; i < data.length; i++) {
    const { dimensions, metrics } = data[i];
    let date: Date = getDate(dimensions[0]);
    if (timeUnit !== "hours") {
      date = d3.timeMinute.offset(date, now.getTimezoneOffset());
    } else {
      date = d3TimeUnit.offset(date, +dimensions[1]);
    }
    if (date.getTime() < startDate.getTime()) {
      startDate = date;
    }
    const value: number = +metrics[0].values[0];
    dateValues.push({ date: date, value: value });
  }

  let d3timeRange: Date[] = d3TimeUnit.range(startDate, now);
  dateValues = backfillDateValues(dateValues, d3timeRange);

  let maxY: number | undefined = d3.max(dateValues.map(x => x.value));
  const yScale = d3
    .scaleLinear()
    .domain([0, maxY || 0])
    .nice()
    .range([parentHeight * (GRAPH_BOTTOM - GRAPH_TOP), 0]);
  var yAxis = d3.axisLeft(yScale).ticks(4);

  function customYAxis(g: any) {
    g.call(yAxis);
    g.call((g: any) => g.select(".domain").style("opacity", 0));
    g.selectAll("line")
      .attr("x1", `${parentWidth * -0.1}`)
      .attr("x2", `${parentWidth * 0.9}`)
      .attr("opacity", 0.5);
  }

  yAxisSVG
    .attr(
      "transform",
      `translate(${parentWidth * GRAPH_SIDE}, ${parentHeight * GRAPH_TOP})`
    )
    .transition(defaultTransition)
    .call(customYAxis);
  yAxisSVG
    .selectAll("text")
    .style("fill", theme.text.colour)
    .style("font-size", "1.7vh");
  yAxisSVG.selectAll("line, path").style("stroke", theme.widget.lineSecondary);

  const xScale = d3
    .scaleTime()
    .domain([dateValues[0].date, dateValues[dateValues.length - 1].date])
    .range([0, parentWidth * (1 - 2 * GRAPH_SIDE)]);

  const xAxis = d3
    .axisBottom(xScale)
    .ticks(d3TimeUnit)
    .tickFormat(filterTicks as (
      dv: Date | { valueOf(): number },
      i: number
    ) => string);

  xAxisSVG
    .attr(
      "transform",
      `translate(${parentWidth * GRAPH_SIDE}, ${parentHeight * GRAPH_BOTTOM})`
    )
    .transition(defaultTransition)
    .call(xAxis);

  xAxisSVG
    .selectAll("text")
    .style("fill", theme.text.colour)
    .style("font-size", "1.7vh");
  xAxisSVG.selectAll("line").style("stroke", theme.paper.background);
  xAxisSVG.selectAll("path").style("stroke", theme.widget.lineSecondary);

  plotSVG
    .selectAll("circle")
    .data(dateValues, (d: DateValue) => d.date.getTime() + d.value)
    .join(
      (enter: any) =>
        enter
          .append("circle")
          .attr("cx", (d: DateValue) => xScale(d.date))
          .attr("cy", (d: DateValue) => yScale(d.value))
          .attr("r", ZERO_RADIUS)
          .call((enter: any) =>
            enter.transition(defaultTransition).attr("r", DEFAULT_RADIUS)
          ),
      (update: any) =>
        update.call((update: any) =>
          update
            .transition(defaultTransition)
            .attr("r", DEFAULT_RADIUS)
            .attr("cx", (d: DateValue) => xScale(d.date))
            .attr("cy", (d: DateValue) => yScale(d.value))
        ),
      (exit: any) =>
        exit.call((exit: any) =>
          exit
            .transition(defaultTransition)
            .attr("r", ZERO_RADIUS)
            .remove()
        )
    )
    .on("mouseover", (d: DateValue, i: number, n: any) => {
      d3.select(n[i]).style("opacity", MOUSEOVER_POINT_OPACITY);
      tooltip.style("opacity", MOUSEOVER_POINT_OPACITY);
      tooltip
        .text(`${d.value} | ${timeFormat(d.date)} `)
        .attr("x", xScale(d.date) - parentWidth * 0.1)
        .attr("y", yScale(d.value) + parentHeight * 0.05);
    })
    .on("mouseout", (d: DateValue, i: number, n: any) => {
      tooltip.style("opacity", 0);
      d3.select(n[i]).style("opacity", DEFAULT_OPACITY);
    })
    .attr(
      "transform",
      `translate(${parentWidth * GRAPH_SIDE}, ${parentHeight * GRAPH_TOP})`
    )
    .style("fill", theme.widget.linePrimary)
    .style("opacity", DEFAULT_OPACITY);

  let lineGenerator: d3.Line<DateValue> = d3
    .line<DateValue>()
    .x((d: DateValue) => xScale(d.date))
    .y((d: DateValue) => yScale(d.value));

  plotSVG
    .selectAll("path")
    .data([dateValues])
    .join(
      (enter: any) =>
        enter
          .append("path")
          .attr("d", (d: DateValue[]) => lineGenerator(d))
          .call((enter: any) => enter.transition(defaultTransition)),
      (update: any) =>
        update.call((update: any) =>
          update
            .transition(defaultTransition)
            .attrTween("d", function(d: DateValue[], i: number, n: any) {
              let previous = d3.select(n[i]).attr("d");
              let current = lineGenerator(d) || "";
              return interpolatePath(previous, current);
            })
        ),
      (exit: any) =>
        exit.call((exit: any) =>
          exit
            .transition(defaultTransition)
            .attr("opacity", 0)
            .remove()
        )
    )
    .attr("fill", "none")
    .attr("stroke", theme.widget.linePrimary)
    .attr("class", "line")
    .attr(
      "transform",
      `translate(${parentWidth * GRAPH_SIDE}, ${parentHeight * GRAPH_TOP})`
    )
    .style("stroke-width", "2");

  function getDate(rawDate: string): Date {
    if (rawDate.includes("-")) {
      try {
        return new Date(rawDate);
      } catch (err) {}
    }
    //dates are in UTC by default
    const isoString: string = `${rawDate.substring(0, 4)}-${rawDate.substring(
      4,
      6
    )}-${rawDate.substring(6, 8)}`;
    return new Date(isoString);
  }

  function getCountableTime(type: string): d3.CountableTimeInterval {
    switch (type) {
      case "hours":
        return d3.timeHour;
      case "days":
        return d3.timeDay;
      case "weeks":
        return getCountableWeek();
      case "months":
        return d3.timeMonth;
      case "years":
        return d3.timeYear;
      default:
        return d3.timeDay;
    }
  }

  function getCountableWeek(): d3.CountableTimeInterval {
    const dayOfWeek = new Date().getDay();
    switch (dayOfWeek) {
      case 6:
        return d3.timeSunday;
      case 0:
        return d3.timeMonday;
      case 1:
        return d3.timeTuesday;
      case 2:
        return d3.timeWednesday;
      case 3:
        return d3.timeThursday;
      case 4:
        return d3.timeFriday;
      case 5:
        return d3.timeSaturday;
      default:
        return d3.timeWeek;
    }
  }

  function getTimeFormat(type: string): (date: Date) => string {
    switch (type) {
      case "hours":
        return d3.timeFormat("%_I %p");
      case "days":
        return d3.timeFormat("%b %e");
      case "weeks":
        return d3.timeFormat("%b %e");
      case "months":
        return d3.timeFormat("%B");
      case "years":
        return d3.timeFormat("%Y");
      default:
        return d3.timeFormat("%b %e");
    }
  }

  function backfillDateValues(
    dateValues: DateValue[],
    timeRange: Date[]
  ): DateValue[] {
    let newDateValues: DateValue[] = [];
    for (let i = 0; i < timeRange.length; i++) {
      newDateValues.push({ date: timeRange[i], value: 0 });
    }
    newDateValues.push({ date: new Date(MAX_TIMESTAMP), value: 0 });
    let rangeIndex = 0;
    let startOfPeriod = timeRange[0];
    for (let i = 0; i < dateValues.length; i++) {
      let thisTime = dateValues[i].date.getTime();
      if (thisTime < startOfPeriod.getTime()) {
        continue;
      }
      while (thisTime >= newDateValues[rangeIndex + 1].date.getTime()) {
        rangeIndex++;
      }
      newDateValues[rangeIndex].value += dateValues[i].value;
    }
    newDateValues.pop(); // ditch max_timestamp
    return newDateValues;
  }

  function filterTicks(dv: Date, i: number): string {
    let lastVal = (dateValues.length - 1) % Math.floor(dateValues.length / 4);
    if (i % Math.floor(dateValues.length / 4) !== lastVal) {
      // if (!(i % 2 === 0)) {
      return "";
    } else {
      return timeFormat(dv);
    }
  }
}
