import { DashTheme } from "../resources/themes";
import { FolderId, MimeType } from "../services/GoogleDrive";

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface Dimension {
  name: string;
}

export interface Metric {
  expression: string;
}

export interface OrderBy {
  fieldName: string;
  sortOrder: OrderType;
}

export interface Filter {
  name: string;
}

export type OrderType = "DESCENDING" | "ASCENDING";

export interface Query {
  parameters?: QueryParams | HanaQueryParams;
  refreshInterval?: number;
}

export interface QueryParams {
  dateRanges: DateRange[];
  metrics: Metric[];
  dimensions: Dimension[];
  orderBys?: OrderBy[];
  filters?: string;
}

export type HanaEndpoint =
  | "getContractActionsTotalsByDateRange"
  | "getCreatedContractTotals"
  | "getContractsByDateRange"
  | "getTotalVolumeMTByDateRange"
  | "getContractTotalsPerSalesRepByDateRange"
  | "getContractTotalsPerSalesGroupByDateRange"
  | "getContractTotalsPerSalesRegionByDateRange";
export interface HanaQueryParams {
  dateRanges: DateRange[];
  dimensions?: Dimension[];
  endpointType: HanaEndpoint;
  filters?: { [name: string]: string };
}

export interface LiveQueryParams {
  metrics: string;
  dimensions?: string;
  sort?: string;
}

export interface QueryMap {
  [key: string]: Query;
}

export function isLiveParams(
  query: QueryParams | LiveQueryParams | HanaQueryParams | undefined
): query is LiveQueryParams {
  if (query === undefined) {
    return false;
  }
  return typeof (query as LiveQueryParams).metrics === "string";
}

export function isHanaParams(
  query: QueryParams | LiveQueryParams | HanaQueryParams | undefined
): query is HanaQueryParams {
  if (query === undefined) {
    return false;
  }
  return typeof (query as HanaQueryParams).endpointType === "string";
}

export interface TimerMap {
  [key: string]: NodeJS.Timeout;
}

export interface Widget {
  query: Query;
  title: string;
  widgetData: WidgetData;
}

interface WidgetSize {
  height: number;
  width: number;
}
export interface WidgetData {
  tile?: Tile;
  data?: AnalyticsData[];
  typeData: TypeData;
  dateRange?: DateRange;
  tooltip?: string;
}

type TimeString = "hours" | "days" | "weeks" | "months" | "years";

export type TypeData =
  | SimpleTextMeta
  | MapNAMeta
  | TimePlotMeta
  | LeaderboardMeta
  | BarChartMeta
  | StackedBarMeta
  | PieChartMeta;

export interface SimpleTextMeta {
  type: "simpleText";
  suffix?: string;
  modifier?: number;
  format?: "value" | "percentage" | "blank" | "time";
  trendline?: SimpleTextTrendline;
  goalValue?: number;
  defaultValue?: string;
}

interface SimpleTextTrendline {
  active: boolean;
  summation?: boolean;
  showGoal?: boolean;
}

export interface StackedBarMeta {
  type: "stackedBar";
}

export interface MapNAMeta {
  type: "mapNorthAmerica";
  display: "points" | "heatmap";
}

export interface TimePlotMeta {
  type: "timePlot";
  timeUnit: TimeString;
  timePeriod: TimeString;
  display: "bar" | "line";
}

export interface LeaderboardMeta {
  type: "leaderboard";
}

export interface BarChartMeta {
  type: "barChart";
  timeUnit?: TimeString;
  timePeriod?: TimeString;
}

export interface PieChartMeta {
  type: "pieChart";
}

export interface WidgetMap {
  [key: string]: Widget[];
}

export interface DateValue {
  date: Date;
  value: number;
}

export interface LabelValue {
  label: string;
  value: number;
}

export interface gaMetric {
  values: any[];
}

export interface AnalyticsData {
  dimensions: any[];
  metrics: gaMetric[];
}

export interface DrawData {
  data?: AnalyticsData[];
  dateRange?: DateRange;
  // initializing: boolean;
  displayProperties: DisplayProperties;
}

export interface DisplayProperties {
  id: string;
  parentWidth: number;
  parentHeight: number;
  contentSVG: any;
  defaultTransition: any;
  theme: DashTheme;
  titleSize: any;
}

export interface UploadParams {
  name: string;
  uploadType: MimeType;
  parentId?: FolderId;
  content?: string;
}

export interface Tile {
  key: string;
  row: number;
  col: number;
  height: number;
  width: number;
}

// export interface Layout {
//   [key: string]: Tile;
// }

// export interface Tile {
//   height: number;
//   width: number;
//   row: number;
//   col: number;
//   occupied: boolean;
// }

export function widgetFits(widgetSize: WidgetSize, tile: Tile | WidgetSize) {
  return widgetSize.height === tile.height && widgetSize.width === tile.width;
}
