import React from "react";
import Home from "../Home";
import Help from "../Help";

import { Switch, Route, BrowserRouter } from "react-router-dom";

interface Props {}
const App: React.SFC<Props> = props => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} />
          <Route path="/help" component={Help} />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
