import React, { useEffect, useRef } from "react";
import Draw from "../../../services/Draw";
import { WidgetData } from "../../../Interfaces";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    d3Div: {
      height: "100%"
    }
  });

interface Props extends WithStyles<typeof styles> {
  widgetData: WidgetData;
  index: number;
  widgetKey: string;
  title: string;
  canCycle: boolean;
  requestData: (key: string) => void;
  cycleWidgetVersion: (key: string) => void;
}

interface DrawProps {
  widgetKey: string;
  widgetData: WidgetData;
  id: string;
  title: string;
  canCycle: boolean;
  cycleWidgetVersion: (key: string) => void;
}

const Viz: React.FunctionComponent<Props> = props => {
  const {
    classes,
    index,
    requestData,
    title,
    widgetData,
    widgetKey,
    canCycle,
    cycleWidgetVersion
  } = props;
  const id = `widget-${index}`;

  let lastType: string = usePrevious(widgetData.typeData.type);
  let clearDrawing: boolean = lastType !== widgetData.typeData.type;
  let dataDefined = widgetData.data === undefined;

  useEffect(() => {
    const newProps: DrawProps = {
      widgetKey: widgetKey,
      widgetData: widgetData,
      id: id,
      title: title,
      canCycle: canCycle,
      cycleWidgetVersion: cycleWidgetVersion
    };
    const reDraw = () => {
      Draw(newProps, true);
    };
    //did mount + render equivalent
    window.addEventListener("resize", reDraw);

    Draw(newProps, clearDrawing);

    return () => {
      window.removeEventListener("resize", reDraw);
    };
  }, [
    widgetKey,
    id,
    title,
    canCycle,
    cycleWidgetVersion,
    widgetData,
    dataDefined,
    clearDrawing
  ]);

  useEffect(() => {
    //component did mount equivalent
    requestData(widgetKey);
    return () => {};
  }, [requestData, widgetKey]);

  return <div className={classes.d3Div} id={id} />;
};

function usePrevious(value: any): any {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default withStyles(styles)(Viz);
