import * as d3 from "d3";
import { DrawData, LabelValue, BarChartMeta } from "../../../Interfaces";

export default function barChart(
  drawData: DrawData,
  barChartMeta: BarChartMeta
) {
  //   const { timePeriod, timeUnit } = barChartMeta;
  const { data, displayProperties } = drawData;
  const {
    id,
    parentWidth,
    parentHeight,
    contentSVG,
    defaultTransition,
    theme
  } = displayProperties;

  let xAxisSVG, yAxisSVG, barSVG;

  barSVG = contentSVG.select(`#${id} #bar-${id}`);
  if (barSVG.empty()) {
    barSVG = contentSVG.append("g").attr("id", `bar-${id}`);
  }
  yAxisSVG = contentSVG.select(`#${id} #y-${id}`);
  if (yAxisSVG.empty()) {
    xAxisSVG = contentSVG.append("g").attr("id", `x-${id}`);
  }
  yAxisSVG = contentSVG.select(`#${id} #y-${id}`);
  if (yAxisSVG.empty()) {
    yAxisSVG = contentSVG.append("g").attr("id", `y-${id}`);
  }

  if (data !== undefined) {
    let barData: LabelValue[] = [];
    let maxY = Number.NEGATIVE_INFINITY;
    for (let i = 0; i < data.length; i++) {
      const { dimensions, metrics } = data[i];
      const value: number = +metrics[0].values[0];
      if (value > maxY) {
        maxY = value;
      }
      barData.push({ label: dimensions[0], value: value });
    }

    maxY *= 1.3;
    let maxX = barData.length - 1;

    const xScale = d3
      .scaleBand()
      .domain(barData.map((d: LabelValue) => d.label)) //temp
      .range([0, parentWidth * 0.8]);
    const yScale = d3
      .scaleLinear()
      .domain([0, maxY])
      //   .domain([0, d3.max(barData, (d: LabelValue) => d.value) || 0])
      //   .nice()
      .range([parentHeight * 0.8, 0]);
    const xAxis = d3
      .axisBottom(xScale)
      .ticks(maxX)
      .tickFormat((d, i) => `${barData[i].label.substring(0, 3)}`);
    var yAxis = d3.axisLeft(yScale).ticks(4);

    barSVG
      .selectAll("rect")
      .data(barData)
      .join("rect")
      .attr(
        "x",
        (d: LabelValue) => xScale(d.label) || 0 + xScale.bandwidth() * 0.1
      )

      .attr("width", xScale.bandwidth() * 0.8)
      .attr(
        "transform",
        `translate(${parentWidth * 0.1}, ${parentHeight * 0.1})`
      )
      .attr("fill", theme.widget.linePrimary)
      .style("opacity", 0.7)
      .transition(defaultTransition)
      .attr("y", (d: LabelValue) => yScale(d.value))
      .attr("height", (d: LabelValue) => yScale(0) - yScale(d.value));

    xAxisSVG
      .attr(
        "transform",
        `translate(${parentWidth * 0.1}, ${parentHeight * 0.9})`
      )
      .transition(defaultTransition)
      .call(xAxis);
    yAxisSVG
      .attr(
        "transform",
        `translate(${parentWidth * 0.1}, ${parentHeight * 0.1})`
      )
      .transition(defaultTransition)
      .call(customYAxis);
    xAxisSVG
      .selectAll("text")
      .style("fill", theme.text.colour)
      .style("font-size", "1.8em");
    xAxisSVG.selectAll("line").style("stroke", theme.paper.background);
    xAxisSVG.selectAll("path").style("stroke", theme.widget.lineSecondary);
    yAxisSVG
      .selectAll("text")
      .style("fill", theme.text.colour)
      .style("font-size", "1.8em");
    yAxisSVG
      .selectAll("line, path")
      .style("stroke", theme.widget.lineSecondary);
  }

  function customYAxis(g: any) {
    g.call(yAxis);
    g.select(".domain").remove();
    g.selectAll("line")
      .attr("x1", `${parentWidth * -0.1}`)
      .attr("x2", `${parentWidth * 0.9}`)
      .attr("opacity", 0.5);
  }
}
