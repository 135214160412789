import React from "react";
import GoogleSignout from "../../atoms/GoogleSignout";
import Viz from "../../molecules/Viz";
import GoogleAnalytics from "../../../services/GoogleAnalytics";
import { DashTheme, geckoThemeDark } from "../../../resources/themes";
import {
  WidgetData,
  Query,
  QueryParams,
  TimerMap,
  Widget,
  WidgetMap,
  LiveQueryParams,
  HanaQueryParams,
  isLiveParams,
  isHanaParams,
  AnalyticsData,
  // Layout,
  Tile,
  HanaEndpoint,
  Dimension
} from "../../../Interfaces";
import {
  createStyles,
  withStyles,
  WithStyles,
  Paper,
  Theme
} from "@material-ui/core";

import { gaMetric } from "../../../Interfaces";
import nxpAPI from "../../../services/nxpAPI";

const dashboardTheme: DashTheme = geckoThemeDark;
const CYCLE_SLEEP = 160;
const CYCLE_WIDGET_DELAY = 13000;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "100%",
      flexDirection: "column",
      display: "flex",
      background: dashboardTheme.root.background,
      fontFamily: dashboardTheme.text.fontFamily
    },
    wrapper: {
      display: "grid",
      flex: 1,
      gridGap: dashboardTheme.root.gridgap,
      margin: theme.spacing(1),
      gridTemplateColumns: "repeat(20, minmax(25px, 1fr))",
      gridTemplateRows: "repeat(16, 1fr)",
      gridAutoFlow: "dense",
      justifyItems: "stretch",
      alignItems: "stretch",
      height: `calc(100% - ${2 * theme.spacing(1)}px)`
    },
    grid: {
      //   background: theme.palette.primary.dark
    },
    paper: {
      textAlign: "center",
      color: theme.palette.text.secondary,
      background: dashboardTheme.paper.background,
      whiteSpace: "nowrap",
      height: "100%",
      borderRadius: dashboardTheme.paper.cornerRadius,
      fontSize: "1.7vh"
    },
    signout: {
      position: "absolute",
      bottom: 0,
      right: 0,
      margin: theme.spacing(1)
    }
  });

interface Props extends WithStyles<typeof styles> {
  analyticsId: string;
  widgets: WidgetMap;
  layoutConfig: Tile[];
  signOut: () => void;
}
interface WidgetStateMap {
  [key: string]: WidgetData;
}
interface State {
  activeWidgets: string[];
  inactiveWidgets: string[];
  widgetStateMap: WidgetStateMap;
}

class Dashboard extends React.Component<Props, State> {
  private widgetTimers: TimerMap = {};
  private otherTimers: NodeJS.Timeout[] = [];
  private widgetKeys: string[] = [];
  private activeWidgetsByPosition: string[] = [];
  private ga: GoogleAnalytics;
  private nxp = new nxpAPI();

  constructor(props: Props) {
    super(props);
    this.state = { activeWidgets: [], inactiveWidgets: [], widgetStateMap: {} };
    this.requestData = this.requestData.bind(this);
    this.cycleWidgetVersion = this.cycleWidgetVersion.bind(this);
    this.cycleAllWidgetVersions = this.cycleAllWidgetVersions.bind(this);
    this.updateData = this.updateData.bind(this);
    this.initRequest = this.initRequest.bind(this);
    this.ga = new GoogleAnalytics(this.props.analyticsId);
  }

  async componentDidMount() {
    const { widgets } = this.props;
    await Object.keys(widgets).forEach(widgetKey => {
      this.setState(
        this.updateData(widgetKey, widgets[widgetKey][0].widgetData)
      );
      this.widgetKeys.push(widgetKey);
    });
    setInterval(() => window.location.reload(), 1000 * 60 * 60 * 1);
    this.widgetKeys.map((key: string) => this.fillTiles(key));

    this.activeWidgetsByPosition = [...this.state.activeWidgets];
    this.activeWidgetsByPosition.sort((a: string, b: string) =>
      this.sortByTilePosition(a, b)
    );

    this.otherTimers.push(
      setInterval(() => {
        this.cycleAllWidgetVersions();
      }, CYCLE_WIDGET_DELAY)
    );
  }

  sortByTilePosition(keyA: string, keyB: string): number {
    let aTile = this.state.widgetStateMap[keyA].tile;
    let bTile = this.state.widgetStateMap[keyB].tile;
    if (aTile && bTile) {
      if (aTile.row === bTile.row) {
        return aTile.col - bTile.col;
      } else {
        return aTile.row - bTile.row;
      }
    } else {
      return 0;
    }
  }

  async initRequest(key: string) {
    const { widgets } = this.props;
    if (widgets[key] !== undefined) {
      let id = key as keyof State;
      let query: Query = widgets[id][0].query;
      if (query !== undefined) {
        const { refreshInterval, parameters } = query;
        this.requestData(id, parameters);
        if (refreshInterval !== undefined) {
          this.widgetTimers[id] = setInterval(
            () => this.requestData(id, parameters),
            refreshInterval
          );
        }
      }
    }
  }

  async cycleWidgetVersion(key: string) {
    const { widgets } = this.props;
    let newWidgets: Widget[] = [...widgets[key]];
    if (newWidgets.length <= 1) {
      return;
    }
    let frontWidget: Widget | undefined = newWidgets.shift();
    if (frontWidget !== undefined) {
      newWidgets.push(frontWidget);
      widgets[key] = newWidgets;
      let newWidget: Widget = newWidgets[0];
      await this.setState(this.updateData(key, newWidget.widgetData));
      clearInterval(this.widgetTimers[key]);
      this.initRequest(key);
    }
  }

  async cycleAllWidgetVersions() {
    const { widgets } = this.props;
    function sleep(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    for (let i = 0; i < this.activeWidgetsByPosition.length; i++) {
      let key = this.activeWidgetsByPosition[i];
      let newWidgets: Widget[] = [...widgets[key]];
      if (newWidgets.length > 1) {
        this.cycleWidgetVersion(key);
        await sleep(CYCLE_SLEEP);
      }
    }
  }

  componentWillUnmount() {
    Object.keys(this.widgetTimers).forEach(id => {
      let timer: NodeJS.Timeout = this.widgetTimers[id];
      clearInterval(timer);
    });
    this.otherTimers.forEach(interval => {
      clearInterval(interval);
    });
  }

  updateData = (key: keyof WidgetStateMap, value: WidgetData) => (
    prevState: State
  ): State => ({
    widgetStateMap: { ...prevState.widgetStateMap, [key]: value },
    activeWidgets: [...prevState.activeWidgets],
    inactiveWidgets: [...prevState.inactiveWidgets]
  });

  async requestData(
    id: keyof WidgetStateMap,
    query: QueryParams | LiveQueryParams | HanaQueryParams | undefined
  ) {
    if (query === undefined) {
      return;
    }
    let widgetData = this.state.widgetStateMap[id];
    let response: Response | gapi.client.HttpRequestFulfilled<any>;
    if (isLiveParams(query)) {
      response = await this.ga.queryLiveAnalytics(query);
      let responseJSON = await response.json();
      widgetData.data = this.standardizeLiveData(
        responseJSON.totalsForAllResults
      );
    } else if (isHanaParams(query)) {
      // try {
      response = await this.nxp.query(query);
      widgetData.data = this.standardizeHanaData(
        await response.text(),
        query.endpointType,
        query.dimensions,
        query.filters
      );
      // } catch (e) {
      //   console.warn(e);
      // }
    } else {
      response = await this.ga.queryAnalytics(query);
      let responseJSON = await response.json();

      widgetData.data = responseJSON.reports[0].data.rows;
      if (
        widgetData.data === undefined &&
        responseJSON.reports[0].data.totals[0].values[0] === "0"
      ) {
        widgetData.data = [];
      }
      widgetData.dateRange = this.ga.localizeDateRanges(query.dateRanges)[0];
    }
    this.setState(this.updateData(id, widgetData));
  }

  standardizeHanaData(
    response: any,
    type: HanaEndpoint,
    dimensions: Dimension[] | undefined,
    filters: { [name: string]: string } | undefined
  ) {
    let hanaData: AnalyticsData[] = [];
    if (dimensions === undefined) {
      return hanaData;
    }
    let metrics: gaMetric[];
    let dimNames: string[] = [];
    let nameSum: { [name: string]: number } = {};
    dimensions.forEach(dim => {
      dimNames.push(dim.name);
    });

    let responseObject = JSON.parse(response);
    let results: any[] = [];
    if (Array.isArray(responseObject.results)) {
      results = responseObject.results;
    } else {
      results.push(responseObject.results);
    }
    if (filters !== undefined) {
      let filterNames = Object.keys(filters);
      for (let j = 0; j < results.length; j++) {
        let passedFilter: boolean = true;
        for (let n = 0; n < filterNames.length; n++) {
          if (results[j][filterNames[n]] !== filters[filterNames[n]]) {
            passedFilter = false;
            break;
          }
        }
        if (passedFilter) {
          let keys = Object.keys(results[j]);
          for (let i = 0; i < keys.length; i++) {
            if (dimensions.length === 0) {
              dimNames.push(keys[i]);
            }
            if (dimNames.includes(keys[i])) {
              if (nameSum[results[j][keys[i]]] === undefined) {
                nameSum[results[j][keys[i]]] = 0;
              }
              nameSum[results[j][keys[i]]] += +results[j]["total"];
            }
          }
        }
      }
      let newKeys = Object.keys(nameSum);
      for (let i = 0; i < newKeys.length; i++) {
        metrics = [{ values: [nameSum[newKeys[i]]] }];
        hanaData.push({ metrics: metrics, dimensions: [newKeys[i]] });
      }
    } else {
      dimensions.forEach(dim => {
        nameSum[dim.name] = 0;
      });
      for (let j = 0; j < results.length; j++) {
        if (results[j] === undefined) {
          continue;
        }
        let keys = Object.keys(results[j]);
        for (let i = 0; i < keys.length; i++) {
          if (dimensions.length === 0) {
            dimNames.push(keys[i]);
          }
          if (dimNames.includes(keys[i])) {
            if (nameSum[keys[i]] === undefined) {
              nameSum[keys[i]] = 0;
            }
            nameSum[keys[i]] += +results[j][keys[i]];
          }
        }
      }
      for (let i = 0; i < dimNames.length; i++) {
        metrics = [{ values: [nameSum[dimNames[i]]] }];
        hanaData.push({ metrics: metrics, dimensions: [dimNames[i]] });
      }
    }

    return hanaData;
  }

  standardizeLiveData(totals: any) {
    let gaData: AnalyticsData[] = [];
    if (totals === undefined) {
      return totals;
    }
    for (let i = 0; i < totals.length; i++) {
      let metrics: gaMetric[] = [];
      let dimensions = [];
      let rowLength = totals[i].length;
      for (let j = 0; j < rowLength - 1; j++) {
        dimensions.push(totals[i][j]);
      }
      metrics.push({ values: [totals[i][rowLength - 1]] });
      gaData.push({ metrics: metrics, dimensions: dimensions });
    }
    return gaData;
  }

  fillTiles(widgetKey: string) {
    const { widgets, layoutConfig } = this.props;
    let activeWidgets: string[] = [...this.state.activeWidgets];
    let inactiveWidgets: string[] = [...this.state.inactiveWidgets];
    for (let i = 0; i < layoutConfig.length; i++) {
      let tile: Tile = layoutConfig[i];
      if (tile.key === widgetKey) {
        activeWidgets.push(widgetKey);
        widgets[widgetKey].forEach((version: Widget) => {
          version.widgetData.tile = tile;
        });
        this.setState({ activeWidgets: activeWidgets });
        return;
      }
    }
    inactiveWidgets.push(widgetKey);
    this.setState({ inactiveWidgets: inactiveWidgets });
  }

  generateWidget(widgetKey: string, index: number) {
    const { widgets } = this.props;
    const { classes } = this.props;
    const widget: Widget = widgets[widgetKey][0];

    let tile = widget.widgetData.tile;
    if (tile !== undefined) {
      const { row, col, height, width } = tile;
      let title = widget.title;
      let canCycle: boolean = widgets[widgetKey].length > 1;
      return (
        <div
          className={classes.grid}
          style={{
            gridColumn: `${col}/ span ${width}`,
            gridRow: `${row} / span ${height}`
          }}
          key={`${widgetKey}-grid`}
        >
          <Paper className={classes.paper}>
            <Viz
              key={`${widgetKey}-paper`}
              index={index}
              title={title}
              widgetData={this.state.widgetStateMap[widgetKey]}
              widgetKey={widgetKey}
              requestData={this.initRequest}
              canCycle={canCycle}
              cycleWidgetVersion={this.cycleWidgetVersion}
            />
          </Paper>
        </div>
      );
    }
  }

  render() {
    const { classes, signOut } = this.props;
    return (
      <React.Fragment>
        {Object.keys(this.state).length > 1 &&
        this.state.constructor === Object ? (
          <div className={classes.root}>
            <div className={classes.wrapper}>
              {this.state.activeWidgets.map((key: string, index: number) =>
                this.generateWidget(key, index)
              )}
            </div>
            <div className={classes.signout}>
              <GoogleSignout signOut={signOut} />
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Dashboard);
